@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

/*
------------------------------------------------------------
Project Name : FasTab;
------------------------------------------------------------
*/

/* Reset 
--------------------------------------------------------------------------------------------------------*/

/* 
http://meyerweb.com/eric/tools/css/reset/
v2.0 | 20110126
License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.bold {
  font-weight: 700 !important;
}

.ml-auto {
  margin-left: auto !important;
}

.MuiButton-root.btn-h-40 {
  min-height: 40px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* General 
--------------------------------------------------------------------------------------------------------*/
*,
*:after,
*:before {
  outline: none !important;
  box-sizing: border-box;
}

a {
  transition: all 0.3s;
}

html,
body {
  min-width: 320px;
  min-height: 100vh;
  background: #f3f5f8;
}

body {
  font-size: 16px;
  line-height: 22px;
  font-family: 'Poppins', sans-serif;
  -ms-overflow-style: scrollbar;
  -webkit-font-smoothing: subpixel-antialiased;
}

.wrapper {
  min-height: 100vh;
  background: #f3f5f8;
  padding-left: 246px;
  transition: all 0.3s ease;
}

.formm-wrapper {
  min-height: 100vh;
  background: #f3f5f8;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.fastab-logo {
  height: 60px;
}

.from-mobile-logo {
  display: block;
  margin: 0 auto 20px;
}

.wrapper.with-footer {
  padding-bottom: 100px;
}

a {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

a:hover {
  text-decoration: none;
}

h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
h6 span {
  display: block;
}

h5 {
  font-size: 20px;
  line-height: 24px;
}

h6 {
  font-size: 18px;
  line-height: 20px;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  -webkit-appearance: none;
  appearance: none;
}

::-webkit-input-placeholder {
  color: #000;
}

::-moz-placeholder {
  color: #000;
}

:-ms-input-placeholder {
  color: #000;
}

:-moz-placeholder {
  color: #000;
}

::-moz-focus-inner {
  border: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

option:not(:checked) {
  color: black;
}

.primary-link {
  color: #008cff !important;
  text-decoration: none !important;
}

.primary-link:hover,
.primary-link:focus {
  color: #008cff !important;
  text-decoration: underline !important;
}

.MuiGrid-container {
  margin-top: 0 !important;
}

.MuiGrid-container .MuiGrid-item {
  padding-top: 0 !important;
}

.overlay button {
  position: fixed;
  opacity: 0;
  transition: all 0.3s ease;
  visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  margin: 0;
  border: none;
  background: rgba(0, 0, 0, 0.5);
}

.section-title {
  color: #737373;
  font-weight: 600;
  padding: 20px 0;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.p-0 {
  padding: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  box-shadow: 0 0 0px 1000px #ffffff inset !important;
  font-family: 'Poppins', sans-serif;
}

/* Buttons
--------------------------------------------------------------------------------------------------------*/
.MuiButton-root.MuiButtonBase-root {
  font-size: 16px;
  border-radius: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
}

.MuiButton-root.MuiButtonBase-root img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.MuiButton-root.MuiButton-containedPrimary {
  color: #fff;
  background: #008cff;
  box-shadow: none;
}

.MuiButton-root.MuiButton-containedPrimary:hover,
.MuiButton-root.MuiButton-containedPrimary:focus,
.MuiButton-root.MuiButton-containedPrimary.active,
.MuiButton-root.MuiButton-containedPrimary:active {
  background: #007ee6;
}

.MuiButton-root.icon-btn {
  padding: 0;
  height: 40px;
  width: 40px;
  min-width: unset;
  min-height: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #b6c2d3;
  background: #fff;
  border-radius: 6px;
}

.MuiButton-root.icon-btn.MuiButton-containedPrimary {
  background: #008cff;
  border: none;
}

.MuiButton-root.icon-btn.rounded {
  border-radius: 50%;
}

.MuiButton-root.icon-btn.light-bordered {
  border-color: #d8d8d8;
}

.MuiButton-root.icon-btn img {
  display: block;
  height: 20px;
  margin: 0;
  width: 20px;
}

.MuiButton-root.icon-btn:hover,
.MuiButton-root.icon-btn:focus {
  background-color: rgba(0, 0, 0, 0.05);
}

.MuiButton-root.icon-btn.MuiButton-containedPrimary:hover,
.MuiButton-root.icon-btn.MuiButton-containedPrimary:focus {
  background: #007ee6;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-sizeSmall {
  font-size: 14px;
  line-height: 20px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlined {
  background: #fff;
  border: 1px solid #ddd;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlined:hover {
  background: #f5f5f5;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlinedPrimary {
  padding-top: 11px;
  padding-bottom: 11px;
  color: #008cff;
  border-color: #008cff;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlined
  span:not(.MuiTouchRipple-root) {
  color: #737373;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlinedSecondary {
  color: #737373;
  border-color: #d8d8d8;
}

.btn-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.btn-list .MuiButton-root:not(:last-child) {
  margin-right: 20px;
}

.btn-list .MuiButton-root {
  min-width: 140px;
  margin-bottom: 10px;
}

.btn-list .right {
  margin-left: auto;
}

.check-connectivity-btn {
  min-width: fit-content !important;
  padding: 5px !important;
}

.pos-config-error-txt {
  color: #d32f2f;
  font-size: 15px;
  padding: 10px;
  font-family: Poppins, sans-serif;
  margin-left: 48%;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlinedPrimary:hover,
.MuiButton-root.MuiButtonBase-root.MuiButton-outlinedPrimary:focus {
  border-color: #008cff;
  background: rgba(0, 140, 255, 0.07);
}

.monthly-subscription {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f3f3f3;
  border-radius: 8px;
}

/* login
--------------------------------------------------------------------------------------------------------*/
.login-wrapper {
  min-height: 100vh;
  display: flex;
}

.login-wrapper .left,
.login-wrapper .right {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 50%;
  flex-basis: 50%;
}

.login-wrapper .left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #008cff
    url('./components/Designer/assets/images/login-artwork.svg') center
    no-repeat;
  background-size: cover;
}

.login-wrapper .left h1 {
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  font-weight: 700;
  color: #fff;
}

.login-wrapper .left p {
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}

.login-wrapper .right {
  display: flex;
  align-items: center;
  padding: 30px 20px;
  background: #fff;
}

.login-box {
  max-width: 394px;
  width: 100%;
  margin: 0 auto;
}

.login-box > img {
  display: block;
  margin: 0 auto 44px;
}

.login-box > small {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300;
  color: #737373;
}

.login-box > h4 {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 22px;
  font-weight: 600;
  color: #737373;
}

.forgot-password-continue {
  margin-bottom: 10px !important;
}

.login-box > h6 {
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px;
  padding-bottom: 22px;
  color: #737373;
}

.login-box .form-group {
  margin-bottom: 26px;
}

.login-box .remember-section {
  display: flex;
  margin-top: -12px;
  margin-bottom: 14px;
  align-items: center;
  justify-content: space-between;
}

.login-box .remember-section a {
  font-size: 14px;
  line-height: 21px;
}

.login-box .MuiButton-root.MuiButtonBase-root {
  padding-top: 13px;
  font-weight: 700;
  padding-bottom: 13px;
  width: 100%;
}

/* header
--------------------------------------------------------------------------------------------------------*/
.header {
  background: #fff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
}

.header .inner {
  display: flex;
  max-width: 1920px;
  margin: 0 auto;
  align-items: center;
  padding: 0 15px 0 12px;
}

.header .btn-icon.MuiButton-root {
  min-width: auto;
  width: 60px;
  display: flex;
  min-height: 80px;
  padding: 0;
  border-radius: 0;
  align-items: center;
  justify-content: center;
}

.header .mobile-logo img {
  display: block;
  height: 32px;
}

.header .mobile-logo {
  display: none;
}

.header .btn-icon.MuiButton-root img {
  height: 40px;
  width: 40px;
  margin: 0;
}

.header .btn-icon.MuiButton-root:hover,
.header .btn-icon.MuiButton-root:focus {
  background: #f5f5f5;
}

.header .toggle-menu.MuiButton-root {
  height: 48px;
  margin-right: 12px;
  width: 48px;
  border-radius: 50%;
  min-width: auto;
  min-height: auto;
  padding: 0;
  flex-shrink: 0;
}

.header .toggle-menu.MuiButton-root:hover,
.header .toggle-menu.MuiButton-root:focus {
  background: #f5f5f5;
}

.header .toggle-menu.MuiButton-root img {
  display: block;
  height: 30px;
  width: 30px;
  margin: 0;
}

.header .form-group {
  max-width: 280px;
  width: 100%;
  margin: 0 10px 0 0;
}

.header .inner .form-group:first-child {
  margin-right: 20px;
}

.header .nav-right {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.notification-menu {
  margin-left: 10px;
  margin-right: 18px;
}

.user-menu .MuiButton-root {
  border: 1px solid #d8d8d8;
  text-transform: unset;
  justify-content: flex-start;
  min-width: 170px;
  border-radius: 24px;
  padding: 0;
  padding-right: 15px;
}

.user-menu .MuiButton-root .user-icon {
  height: 42px;
  margin-right: 6px;
  background: rgba(216, 216, 216, 0.5);
  width: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user-menu .MuiButton-root .user-icon img {
  display: block;
  height: 24px;
  width: 24px;
  margin: 0;
}

.user-menu .MuiButton-root .user-info {
  font-size: 14px;
  padding-right: 10px;
  line-height: 19px;
  text-align: left;
  font-weight: 500;
  color: #737373;
}

.user-menu .MuiButton-root .user-info small {
  display: block;
  font-size: 11px;
  font-weight: 300;
}

.user-menu .MuiButton-root > img {
  margin-left: auto;
  margin-right: 0;
  height: 12px;
  width: 12px;
}

.options {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.disbursement-label {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* primary-menu
--------------------------------------------------------------------------------------------------------*/
.primary-menu .MuiMenu-paper {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  width: 100px;
}

.primary-menu.user-menu-popup .MuiMenu-paper {
  min-width: 170px;
}

.primary-menu.user-menu-popup .MuiMenu-paper span {
  font-size: 14px;
  display: none;
  padding: 0 16px 7px;
  line-height: 20px;
  font-weight: 500;
  color: #737373;
}

.primary-menu.user-menu-popup .MuiMenu-paper .MuiDivider-root {
  display: none;
  margin-bottom: 6px;
}

.primary-menu .MuiMenu-paper .info-text small {
  display: block;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root a,
.primary-menu .MuiMenu-paper .MuiMenuItem-root,
.primary-menu .MuiMenu-paper .MuiMenuItem-root .MuiListItemText-primary {
  font-size: 14px;
  line-height: 28px;
  color: #737373;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root .MuiCheckbox-root {
  margin-left: -9px;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root .MuiCheckbox-root img {
  margin-right: 0;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root a {
  display: flex;
  align-items: center;
  text-decoration: none;
  width: 100%;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root a img,
.primary-menu .MuiMenu-paper .MuiMenuItem-root img {
  display: block;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root {
  padding: 2px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
}

.primary-menu.action-menu .MuiMenu-paper .MuiMenuItem-root:not(:last-child) {
  margin-bottom: 6px;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.search-box {
  padding: 8px;
  padding-top: 2px;
  position: relative;
  background: #fff !important;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.search-box span {
  display: block;
  width: 100%;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.search-box span button {
  background: none;
  position: absolute;
  right: 10px;
  top: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  height: 28px;
  width: 28px;
  cursor: pointer;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.search-box span button img {
  height: 18px;
  display: block;
  width: 18px;
  margin: 0;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.search-box input {
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  outline: none;
  height: 34px;
  width: 100%;
  padding: 6px 7px;
  font-family: 'Poppins', sans-serif;
  color: #737373;
  padding-right: 36px;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.search-box input::placeholder {
  color: #a6a6a7;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.search-box input:focus {
  border-color: #008cff;
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.add-item {
  background-color: rgba(25, 118, 210, 0.1);
}

.primary-menu .MuiMenu-paper .MuiMenuItem-root.add-item span {
  font-weight: 500;
  color: #008cff;
  padding-right: 4px;
}

/* sidebar
--------------------------------------------------------------------------------------------------------*/
.sidebar {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  width: 246px;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
}

.sidebar .form-group {
  margin: 0 10px 20px;
  display: none;
}

.sidebar .logo,
.sidebar .logo img {
  display: block;
}

.sidebar .logo {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.main-navigation > li {
  margin-bottom: 10px;
}

.main-navigation > li > a {
  display: flex;
  transition: all 0.3s ease;
  border-left: 2px solid transparent;
  font-size: 16px;
  line-height: 19px;
  color: #737373;
  align-items: center;
  padding: 10px 16px 10px 14px;
}

.main-navigation > li > a span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 14px;
}

.main-navigation > li > a img {
  display: block;
  transition: all 0.3s ease;
  height: 30px;
  width: 30px;
}

.main-navigation > li > a img.active {
  margin-left: -30px;
  opacity: 0;
  visibility: hidden;
}

.main-navigation > li > a:hover,
.main-navigation > li > a:focus {
  color: #008cff;
  background: #f1f6fb;
}

.main-navigation > li > a.active {
  background: #f1f6fb;
  color: #008cff;
}

.main-navigation > li > a.active {
  border-color: #008cff;
}

.main-navigation > li > a:hover img.active,
.main-navigation > li > a:focus img.active,
.main-navigation > li > a.active img.active {
  opacity: 1;
  visibility: visible;
}

.main-navigation > li > a:hover img.default,
.main-navigation > li > a:focus img.default,
.main-navigation > li > a.active img.default {
  opacity: 0;
  visibility: hidden;
}

.sidebar .MuiAccordionSummary-root {
  min-height: auto;
  transition: all 0.3s ease;
  justify-content: flex-start;
  padding: 10px 16px 10px 14px;
  border-left: 2px solid transparent;
}

.sidebar .MuiAccordionSummary-content {
  margin: 0 !important;
  transition: all 0.3s ease;
  font-size: 16px;
  line-height: 19px;
  color: #737373;
  display: flex;
  align-items: center;
  max-width: 198px;
}

.sidebar .MuiAccordion-root {
  box-shadow: none;
}

.sidebar .MuiAccordionSummary-content span {
  display: block;
  padding-left: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s ease;
  overflow: hidden;
}

.sidebar .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.sidebar .MuiAccordionSummary-content img {
  display: block;
  height: 30px;
  width: 30px;
  transition: all 0.3s ease;
}

.sidebar .MuiAccordionSummary-content img.active {
  margin-left: -30px;
  opacity: 0;
  visibility: hidden;
}

.sidebar .MuiAccordionSummary-content.Mui-expanded img.active,
.sidebar
  .MuiAccordionSummary-root:hover
  .MuiAccordionSummary-content
  img.active {
  opacity: 1;
  visibility: visible;
}

.sidebar .MuiAccordionSummary-content.Mui-expanded img.default,
.sidebar
  .MuiAccordionSummary-root:hover
  .MuiAccordionSummary-content
  img.default {
  opacity: 0;
  visibility: hidden;
}

.sidebar .MuiAccordionSummary-content.Mui-expanded,
.sidebar .MuiAccordionSummary-root:hover .MuiAccordionSummary-content {
  color: #008cff;
}

.sidebar .MuiAccordionSummary-root.Mui-expanded {
  background: #f1f6fb;
  border-color: #008cff;
}

.sidebar .MuiAccordionSummary-expandIconWrapper,
.sidebar .MuiAccordionSummary-expandIconWrapper img {
  height: 16px;
  width: 16px;
  transition: all 0.3s ease;
}

.sidebar .MuiAccordionSummary-expandIconWrapper {
  margin-left: auto;
}

.sidebar .MuiAccordionDetails-root {
  padding: 10px 0;
}

.sidebar .MuiAccordionDetails-root a {
  padding: 10px 16px 9px 81px;
  display: block;
  font-size: 16px;
  transition: all 0.3s ease;
  line-height: 19px;
  color: #737373;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar .MuiAccordionDetails-root a::before {
  content: '';
  height: 10px;
  transition: all 0.3s ease;
  width: 10px;
  border-radius: 50%;
  background: #737373;
  position: absolute;
  left: 61px;
  top: 14px;
}

.sidebar .MuiAccordionDetails-root a:hover,
.sidebar .MuiAccordionDetails-root a:focus,
.sidebar .MuiAccordionDetails-root a.active {
  background: #fff;
  color: #008cff;
}

.sidebar .MuiAccordionDetails-root a:hover::before,
.sidebar .MuiAccordionDetails-root a:focus::before,
.sidebar .MuiAccordionDetails-root a.active::before {
  background: #008cff;
}

/* dashboard
--------------------------------------------------------------------------------------------------------*/
.dashboard-tabs .nav {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.dashboard-tabs .nav li {
  font-size: 20px;
  margin-right: 30px;
  color: #737373;
  white-space: nowrap;
  padding-bottom: 16px;
}

.dashboard-tabs .nav li.active {
  color: #008cff;
}

.dateFilterInput {
  border-radius: 4px;
  padding: 13px 0 12px 10px;
  box-sizing: content-box;
  border-color: #d8d8d8;
  border-width: 1px;
  appearance: none;
  border-style: solid;
  line-height: 22px;
  color: #737373;
  font-size: 15px;
  box-sizing: border-box;
  width: 100%;
  font: inherit;
}

.dateFilter {
  display: flex;
  background: #fff;
}

.dateFilter ul {
  border-right: 2px solid rgba(0, 0, 0, 0.12);
}

.dateFilter li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 13px;
}

.dateFilter li:hover {
  background: rgba(0, 0, 0, 0.04);
}

.dateFilter li button {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  background: none;
  color: inherit;
  border: none;
  padding: 8px 12px;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.no-data {
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.01);
  color: rgba(0, 0, 0, 0.5);
  padding: 6px 12px;
  margin: 20px 0;
  font-size: 11px;
}

/* form-group
--------------------------------------------------------------------------------------------------------*/
.form-group .MuiTextField-root .MuiOutlinedInput-input {
  padding: 13px 0 12px 10px;
  color: #737373;
}

.form-group
  .MuiTextField-root
  .MuiOutlinedInput-input.MuiInputBase-inputSizeSmall {
  padding-top: 7px;
  padding-bottom: 8px;
}

.form-group .MuiTextField-root .MuiOutlinedInput-input .placeholder {
  font-size: 15px;
  color: #a6a6a7;
}

.form-group {
  margin-bottom: 20px;
  position: relative;
}

.form-group .MuiFormControl-root {
  width: 100%;
}

.form-group .MuiInputBase-multiline {
  padding: 0;
}

.form-group .MuiInputBase-root {
  background: #fff;
}

.form-group .MuiInputBase-root.MuiInputBase-adornedEnd {
  padding-right: 10px;
}

.form-group .MuiInputBase-root.MuiInputBase-adornedEnd .MuiIconButton-edgeEnd {
  margin-right: -8px;
}

.form-group .MuiInputBase-root.MuiInputBase-sizeSmall {
  padding-right: 8px;
}

.form-group .MuiTextField-root .MuiInputLabel-root {
  font-size: 15px;
  transform: translate(10px, 13px) scale(1);
  line-height: 22px;
  color: #a6a6a7;
  max-width: calc(100% - 40px);
}

.form-group .MuiTextField-root .MuiInputLabel-root.MuiInputLabel-sizeSmall {
  transform: translate(10px, 8px) scale(1);
}

.form-group .MuiTextField-root .MuiInputLabel-root.Mui-error {
  color: #d32f2f;
}

.form-group .MuiTextField-root .MuiOutlinedInput-notchedOutline {
  border-radius: 6px;
}

.form-group .MuiTextField-root .MuiOutlinedInput-notchedOutline span {
  padding-left: 4px;
  padding-right: 3px;
}

.form-group .MuiTextField-root.no-label .MuiOutlinedInput-notchedOutline span {
  padding: 0;
}

.form-group .primary-checkbox.MuiFormControlLabel-root {
  margin-left: -9px;
  margin-right: 5px;
}

.form-group .MuiInputAdornment-root.MuiInputAdornment-positionStart {
  height: 48px;
  border-right: 1px solid #dbdbdb;
  margin: 0;
  max-height: 100%;
}

.form-group .MuiTextField-root .MuiInputLabel-root.MuiInputLabel-shrink {
  transform: translate(14px, -8px) scale(0.75);
  max-width: calc(130% - 40px);
}

.form-group .MuiTextField-root .MuiInputLabel-root.Mui-focused {
  color: #008cff;
}

.form-group .MuiTextField-root .MuiInputLabel-root.Mui-error.Mui-focused {
  color: #d32f2f;
}

.form-group .MuiFormHelperText-root {
  margin-left: 10px;
}

.form-group
  .MuiTextField-root
  .MuiInputBase-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-width: 1px;
  border-color: #008cff;
}

.form-group
  .MuiTextField-root
  .MuiInputBase-root:not(.Mui-focused):hover
  .MuiOutlinedInput-notchedOutline,
.form-group
  .MuiTextField-root
  .MuiInputBase-root
  .MuiOutlinedInput-notchedOutline {
  border-color: #d8d8d8;
  border-width: 1px;
}

.form-group
  .MuiTextField-root
  .MuiInputBase-root.Mui-error:not(.Mui-focused):hover
  .MuiOutlinedInput-notchedOutline,
.form-group
  .MuiTextField-root
  .MuiInputBase-root.Mui-error
  .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f;
}

.form-group .MuiSelect-icon {
  background-image: url('./components/Designer/assets/images/ic-down.svg');
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  right: 10px;
}

.form-group .MuiSelect-icon path {
  display: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.MuiCalendarPicker-root .MuiPickersDay-root.Mui-selected,
.MuiCalendarPicker-root .PrivatePickersYear-yearButton.Mui-selected {
  background-color: #008cff !important;
}

.MuiCalendarPicker-root .MuiButtonBase-root.MuiPickersDay-today {
  border: none;
  background: rgba(0, 140, 255, 0.1);
  color: #008cff;
}

.MuiCalendarPicker-root .MuiButtonBase-root.MuiPickersDay-today.Mui-selected {
  color: #fff;
}

.form-group .with-select .MuiInputBase-root {
  padding-right: 0;
}

.form-group .react-select__control {
  border-color: #d8d8d8;
  min-height: 48px;
  box-shadow: none;
  padding-right: 8px;
  border-radius: 6px;
  background-color: hsl(0deg 0% 99%) !important;
}

.form-group
  .react-select__control:not(.react-select__control--is-focused):hover {
  border-color: #d8d8d8;
}

.form-group .react-select__control.react-select__control--is-focused {
  border-color: #008cff;
}

.form-group .react-select__value-container {
  padding: 2px 10px;
}

.form-group .react-select__input-container {
  margin: 0;
}

.form-group .react-select__input-container,
.form-group .react-select__single-value {
  color: #737373;
  font-size: 16px;
}

.form-group .react-select__single-value {
  margin-left: 0;
}

.form-group .react-select__indicator-separator {
  display: none;
}

.table-cell-trucate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-group .react-select__dropdown-indicator {
  padding: 2px;
  background-image: url('./assets/images/ic-down.svg');
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
}

.form-group .react-select__dropdown-indicator svg path {
  display: none;
}

.form-group
  .react-select__control--is-focused
  .react-select__dropdown-indicator {
  transform: rotate(-180deg);
}

.form-group .react-select__menu {
  margin: 0;
  padding: 4px 0;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border: none;
  border-radius: 6px;
}

.form-group .react-select__menu .react-select__option {
  padding: 2px 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-size: 14px;
  line-height: 28px;
  color: #737373;
}

.form-group
  .react-select__menu
  .react-select__option.react-select__option--is-selected {
  background: rgba(25, 118, 210, 0.08);
}

.form-group
  .react-select__menu
  .react-select__option:not(.react-select__option--is-selected):hover,
.form-group
  .react-select__menu
  .react-select__option:not(.react-select__option--is-selected).react-select__option--is-focused {
  background: rgba(0, 0, 0, 0.04);
}

.form-group .react-select-label {
  font-size: 15px;
  line-height: 22px;
  color: #a6a6a7;
  transform: scale(0.75);
  display: block;
  position: absolute;
  padding: 0px 4px;
  background: #fff;
  top: -11px;
  left: 2px;
}

.form-group .react-select-label.focused {
  color: #008cff;
}

.form-group .MuiAutocomplete-inputRoot.MuiOutlinedInput-root {
  padding: 5px 10px;
}

.form-group
  .MuiAutocomplete-inputRoot.MuiOutlinedInput-root
  .MuiAutocomplete-tag {
  border: 0;
  background: #f3f4f6;
  height: 28px;
  border-radius: 2px;
}

.form-group
  .MuiAutocomplete-inputRoot.MuiOutlinedInput-root
  .MuiAutocomplete-tag
  .MuiChip-label {
  padding-left: 8px;
  padding-right: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
}

.form-group
  .MuiAutocomplete-inputRoot.MuiOutlinedInput-root
  .MuiAutocomplete-tag
  .MuiChip-deleteIcon {
  margin-left: 0;
}

/* primary-checkbox
--------------------------------------------------------------------------------------------------------*/
.primary-checkbox.MuiFormControlLabel-root .MuiTypography-root {
  font-size: 14px;
  line-height: 21px;
  color: #737373;
}

.primary-checkbox.select-all {
  margin-top: 4px;
}

.primary-checkbox.select-all.MuiFormControlLabel-root .MuiTypography-root {
  font-weight: 600;
}

/* page-top
--------------------------------------------------------------------------------------------------------*/
.main-content {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 20px 1px;
  min-height: calc(100vh - 161px);
}

.main-content.no-data {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-content.no-data p {
  font-size: 26px;
  line-height: 32px;
  padding: 30px;
  font-weight: 500;
  color: #737373;
  text-align: center;
}

.card {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  padding: 20px;
  background: #fff;
}

.page-top {
  padding: 24px 0 14px;
  display: flex;
  align-items: center;
  min-height: 76px;
}

.page-top .right {
  display: flex;
  margin-left: auto;
}

.toggle-payment-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 12px;
  color: #737373;
}

.page-top .right .MuiButton-root {
  margin-left: 10px;
}

.page-top .right .MuiButton-root:not(.icon-btn) {
  min-width: 100px;
}

.page-top .right .icon-btn {
  height: 38px;
}

.page-top
  .right
  .icon-btn.MuiButton-root:not(.MuiButton-containedPrimary):hover,
.page-top
  .right
  .icon-btn.MuiButton-root:not(.MuiButton-containedPrimary):focus,
.page-top
  .right
  .icon-btn.MuiButton-root:not(.MuiButton-containedPrimary):active {
  background: #fff;
  border-color: #008cff;
}

.icon-btn-enable {
  min-width: 50px !important;
}

.page-title {
  font-size: 24px;
  line-height: 30px;
  color: #737373;
}

.filter-panel {
  padding-bottom: 20px;
}

.filter-panel .card {
  padding-bottom: 10px;
}

.filter-panel .btn-list {
  margin-bottom: 4px;
}

/* stats
--------------------------------------------------------------------------------------------------------*/
.stats .oval {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  flex-shrink: 0;
  position: relative;
  z-index: 1;
}

.stats::after {
  content: '';
  display: block;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  transition: all 0.45s ease;
  background: linear-gradient(90deg, #008cff 0%, #5fb6f6 100%);
  position: absolute;
  top: 25px;
  right: 25px;
}

.stats:hover::after {
  width: calc(100% + 150px);
  height: calc(100% + 250px);
  top: -125px;
  right: -75px;
}

.stats .oval img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 38px;
  width: 38px;
}

.stats {
  display: flex;
  padding-top: 25px;
  padding-right: 25px;
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  justify-content: space-between;
  min-height: 165px;
  align-items: flex-start;
}

.stats .stats-info {
  padding-right: 10px;
  position: relative;
  z-index: 1;
  flex-grow: 1;
}

.stats .stat-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.stats .stats-info h3 {
  font-size: 16px;
  line-height: 30px;
  color: #007ee6;

  transition: all 0.45s ease;
}

.stats .stats-info h3 img {
  display: inline-block;
  width: 18px;
  fill: red;
}

.stats .dataTypes {
  position: absolute;
  bottom: 15px;
  right: 25px;
  z-index: 30;
}

.stats .dataTypes div {
  display: inline-block;
  margin-left: 10px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}

.stat-ind-fastab {
  width: 28px;
  background-image: url('./assets/images/logo-color.svg');
}

.stats:hover .stat-ind-fastab {
  background-image: url('./assets/images/logo-hover.svg');
}

.stat-ind-fastab-dis {
  width: 28px;
  background-image: url('./assets/images/logo-outline.svg');
}

.stats:hover .stat-ind-fastab-dis {
  background-image: url('./assets/images/logo-outline-hover.svg');
}

.stat-ind-pos {
  width: 20px;
  background-image: url('./assets/images/pos-color.svg');
}

.stat-ind-pos-dis {
  width: 20px;
  background-image: url('./assets/images/pos.svg');
}

.stats:hover .stat-ind-pos-dis {
  background-image: url('./assets/images/pos-hover.svg');
}

.stats .stats-info small {
  font-size: 14px;
  line-height: 30px;
  font-weight: 300;
  transition: all 0.45s ease;
  display: block;
}

.stats .stats-info p {
  font-size: 48px;
  line-height: 50px;
  font-weight: 600;
  transition: all 0.45s ease;
  color: #212121;
}

.stats:hover .stats-info small,
.stats:hover .stats-info h3,
.stats:hover .stats-info td,
.stats:hover .stats-info p {
  color: #fff;
}

.stats .stats-info table {
  width: 100%;
}

.stats .stats-info table td {
  padding: 5px 0;
  font-size: 12px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  transition: all 0.45s ease;
}

.stats .stat-more-info {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  display: inline-block;
  width: 18px;
  height: 18px;
  padding-top: 2px;
  margin-left: 8px;
  background-image: url('./assets/images/info.svg');
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.stats:hover .stat-more-info {
  background-image: url('./assets/images/info-hover.svg');
}

.stats .stats-info .stat-description {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.stat-coming-soon {
  padding: 20px 0;
}

.stat-coming-soon span {
  background: -webkit-linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background: -o-linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background: linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  display: block;
  -webkit-text-fill-color: transparent;
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  text-shadow: 0px 92px 252px #dddddd;
  text-align: center;
}

.stats:hover .stat-coming-soon span {
  background: #ffffff;
  background-clip: text;
  -webkit-background-clip: text;
  display: block;
  -webkit-text-fill-color: transparent;
}

/* error-message
--------------------------------------------------------------------------------------------------------*/
.error-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error-message.coming-soon h1 {
  font-size: 100px;
  line-height: 140px;
}

.error-message h1 {
  background: -webkit-linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background: -o-linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background: linear-gradient(0deg, #3d8af7 34%, #bbdcf3 100%);
  background-clip: text;
  -webkit-background-clip: text;
  display: block;
  -webkit-text-fill-color: transparent;
  font-size: 190px;
  line-height: 190px;
  font-weight: 600;
  text-shadow: 0px 92px 252px #dddddd;
  text-align: center;
  margin-bottom: 28px;
}

.browserIE .error-message h2 {
  background: transparent;
  -webkit-text-fill-color: #3d8af7;
  color: #3d8af7;
}

.browserIE .error-message h1 {
  background: transparent;
  -webkit-text-fill-color: #3d8af7;
  color: #3d8af7;
}

.error-message h2 {
  font-size: 42px;
  line-height: 52px;
  color: #737373;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.error-message p {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  color: #a6a6a7;
  text-align: center;
  max-width: 762px;
  margin: 0 auto 62px;
}

.error-message .MuiButton-root {
  min-width: 170px;
}

/* chart
--------------------------------------------------------------------------------------------------------*/
.chart {
  height: calc(100% - 30px);
  margin-bottom: 30px;
}

.chart h5 {
  color: #737373;
  font-weight: 600;
  padding-bottom: 20px;
}

.chart > img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

/* Alert-dialog
--------------------------------------------------------------------------------------------------------*/
.primary-dialog.faq-dialog .MuiDialog-paper {
  height: 100% !important;
}

.primary-dialog.alert-dialog .MuiDialogContent-root > img {
  height: 60px;
  display: block;
  width: 60px;
}

.primary-dialog.alert-dialog .MuiDialogContent-root {
  min-height: 104px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 0 !important;
}

.primary-dialog.alert-dialog .btn-list .MuiButton-root {
  min-width: 180px;
}

.primary-dialog.alert-dialog .btn-list {
  justify-content: center;
}

.primary-dialog.alert-dialog .MuiDialogContent-root p {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

/* file-upload-wrapper
--------------------------------------------------------------------------------------------------------*/
.file-upload-wrapper h6 {
  color: #737373;
  font-weight: 600;
  text-align: center;
  padding-bottom: 15px;
}

.file-upload-wrapper small {
  font-size: 12px;
  display: block;
  text-align: center;
  line-height: 18px;
  color: #a6a6a7;
  padding-bottom: 15px;
}

.file-upload {
  min-height: 224px;
  display: block;
  border-radius: 6px;
  border: 1px dashed #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 40px 20px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.file-upload.vertical {
  flex-direction: row;
  align-items: center;
  min-height: 180px;
}

.file-upload.vertical span {
  display: block;
}

.file-upload:hover,
.file-upload:focus {
  background: #f9f9f9;
}

.file-upload span {
  font-weight: 400;
  display: none;
  font-size: 12px;
  line-height: 18px;
  color: #a6a6a7;
  display: none;
  padding-top: 4px;
}

.file-upload p {
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  color: #737373;
}

.file-upload strong {
  font-weight: 700;
  color: #008cff;
}

.file-upload img {
  margin-bottom: 16px;
}

.file-upload.vertical img {
  margin: 0 13px 0 0;
}

.uploaded-files li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-right: 8px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
}

.uploaded-files li a {
  display: flex;
  min-height: 60px;
  padding: 9px 0 9px 20px;
  flex-grow: 1;
  align-items: center;
}

.uploaded-files li a > img {
  display: block;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.uploaded-files li > span {
  display: flex;
  min-height: 60px;
  padding: 9px 0 9px 20px;
  flex-grow: 1;
  align-items: center;
}

.uploaded-files li > span > img {
  display: block;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.uploaded-files li .file-info {
  font-size: 12px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  color: #737373;
}

.uploaded-files li .file-info span,
.uploaded-files li .file-info small {
  display: block;
}

.uploaded-files li .file-info span {
  font-weight: 500;
}

.uploaded-files li .icon-btn {
  border: none;
  flex-shrink: 0;
}

.uploaded-files li .icon-btn img {
  height: 24px;
  width: 24px;
}

.file-preview {
  height: 224px;
  padding: 15px 10px;
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  overflow: hidden;
}

.file-preview img {
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.file-preview .icon-btn {
  position: absolute;
  top: 5px;
  transition: all 0.3s ease;
  right: -40px;
  opacity: 0;
}

.file-preview .icon-btn:hover,
.file-preview .icon-btn:focus {
  background: #eee;
}

.file-preview:hover .icon-btn {
  right: 5px;
  opacity: 1;
}

/* primary-switch
--------------------------------------------------------------------------------------------------------*/
.primary-switch .MuiSwitch-root .MuiSwitch-thumb {
  height: 16px;
  box-shadow: none;
  width: 25px;
  border-radius: 40px;
}

.primary-switch.small-switch .MuiSwitch-root .MuiSwitch-thumb {
  width: 16px;
}

.primary-switch .MuiSwitch-root {
  padding: 5px 4px 5px;
  height: 34px;
}

.primary-switch.small-switch .MuiSwitch-root {
  width: 48px;
}

.primary-switch .MuiSwitch-switchBase {
  padding: 9px 8px;
}

.primary-switch .MuiSwitch-track {
  border-radius: 40px;
  background: #d8d8d8;
  opacity: 1;
}

.primary-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background: #008cff;
  opacity: 1;
}

.primary-switch .MuiSwitch-switchBase.Mui-checked {
  color: #fff;
  transform: translateX(17px);
}

.primary-switch.small-switch .MuiSwitch-switchBase.Mui-checked {
  transform: translateX(15px);
}

.primary-switch .MuiFormControlLabel-label {
  font-size: 12px;
  padding-right: 8px;
  line-height: 18px;
  color: #737373;
  font-weight: 500;
}

.primary-switch.MuiFormControlLabel-root {
  margin-left: 0;
}

/* menu-wrapper
--------------------------------------------------------------------------------------------------------*/
.menu-wrapper.card {
  padding-left: 30px;
  margin-bottom: 30px;
  padding-right: 52px;
  padding-top: 24px;
}

.menu-content {
  display: flex;
}

.menu-content > .left {
  margin-right: 52px;
  max-width: calc(100% - 426px);
  flex-basis: calc(100% - 426px);
  flex-grow: 0;
  flex-shrink: 0;
}

.menu-content > .left h6 {
  color: #737373;
  padding-bottom: 24px;
}

.menu-content > .right {
  max-width: 374px;
  flex-basis: 374px;
  flex-shrink: 0;
  flex-grow: 0;
}

.menu-content > .right img {
  display: block;
  max-width: 100%;
  margin-top: 10px;
}

.menu-accordion-item-count {
  display: flex;
  align-items: center;
  font-size: 12px !important;
  font-weight: 450;
}

.menu-subcategory-accordion-name {
  max-width: 75%;
}

/* draggable-list
--------------------------------------------------------------------------------------------------------*/
.draggable-list .draggable-item.inactive {
  background-color: #ececec !important;
}

.draggable-list li.draggable-item {
  display: flex;
  padding: 16px 20px 16px 14px;
  min-height: 74px;
  align-items: center;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  margin-bottom: 30px;
  background: #fff;
}

.draggable-list .draggable-item.dragging {
  background: #e1f0fc;
  border-color: #e1f0fc;
}

.draggable-list .drag-handler {
  display: flex;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  flex-shrink: 0;
}

.draggable-list .drag-handler img {
  display: block;
  height: 30px;
  width: 30px;
}

.draggable-list.category-list .drag-handler img {
  height: 24px;
  width: 24px;
}

.draggable-list.category-list .drag-handler {
  height: 28px;
  margin-right: 6px;
  width: 28px;
}

.draggable-list.category-list li.draggable-item {
  padding: 0 10px 0 0;
  margin-bottom: 10px;
  min-height: 42px;
}

.draggable-list.category-list li.draggable-item.active {
  background: #e1f0fc;
}

.draggable-list.category-list li.draggable-item a {
  text-decoration: none;
  padding: 3px 0 3px 5px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: calc(100% - 170px);
}

.draggable-list.category-list .draggable-item .right {
  margin-left: auto;
  flex-shrink: 0;
  padding: 5px;
  /* background: #ffffff; */
  border-radius: 0 6px 6px 0;
  opacity: 1;
  z-index: 1;
}

/* .draggable-list.category-list .draggable-item:hover > .right {
  opacity: 1;
} */

.draggable-list.category-list .icon-btn {
  height: 30px;
  width: 30px;
}

.draggable-list.category-list li.draggable-item.active .icon-btn:hover,
.draggable-list.category-list li.draggable-item.active .icon-btn:focus {
  background: #fff;
}

.draggable-list.category-list .icon-btn img {
  height: 15px;
  width: 15px;
}

.draggable-list.category-list .item-label {
  font-size: 16px;
  line-height: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.draggable-list.category-item-list .drag-handler img {
  height: 18px;
  width: 18px;
}

.draggable-list.category-item-list .drag-handler {
  height: 20px;
  margin-right: 5px;
  width: 20px;
}

.draggable-list.category-item-list .draggable-item {
  padding: 5px 10px;
  margin-bottom: 10px;
  min-height: 36px;
}

.draggable-list.category-item-list .icon-btn {
  height: 24px;
  width: 24px;
}

.draggable-list.category-item-list .icon-btn img {
  height: 14px;
  width: 14px;
}

.draggable-list.category-item-list .item-label {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0 4px 0 0;
  line-height: 16px;
  max-width: calc(100% - 144px);
}

.draggable-list.category-item-list .item-label span {
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
}

.draggable-list.category-item-list .item-label small {
  letter-spacing: 0.5px;
}

.draggable-list .item-label {
  font-size: 20px;
  display: block;
  flex-grow: 1;
  padding-right: 10px;
  line-height: 24px;
  font-weight: 600;
  color: #737373;
}

.draggable-list .right {
  margin-left: auto;
  display: block;
  display: flex;
  align-items: center;
}

.draggable-list .right .MuiButton-root {
  margin-left: 10px;
}

.draggable-list.category-item-list .right .MuiButton-root {
  margin-left: 6px;
}

.draggable-list .right .primary-switch {
  margin-top: -5px !important;
}

.draggable-list .action-menu-btn {
  display: none;
}

/*-------------------------*/
.category-draggable-accordion .MuiAccordion-root:before {
  display: none;
}

.category-draggable-accordion .MuiAccordion-root:not(.Mui-expanded) {
  background: none;
  border: 0;
  box-shadow: none;
}

.category-draggable-accordion .MuiAccordionSummary-root {
  min-height: 40px !important;
  padding: 0;
}

.category-draggable-accordion .MuiAccordionSummary-root .right {
  margin-right: -30px;
  padding-right: 10px !important;
}

.category-draggable-accordion .MuiAccordionSummary-content {
  margin: 0 !important;
}

.category-draggable-accordion li.draggable-item.inactive {
  background-color: #ececec !important;
}

.category-draggable-accordion .MuiAccordionDetails-root {
  padding: 0;
  border-top: solid 1px #e4e4e4;
}

.category-draggable-accordion li.draggable-item,
.draggable-list .MuiAccordionSummary-content .draggable-item {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0 7px;
  min-height: auto;
  margin: 0 !important;
  min-height: auto;
  border-radius: 0;
  border: 0;
}

.category-draggable-accordion li.draggable-item + li.draggable-item {
  border-top: solid 1px #e4e4e4;
}

.category-draggable-list-element {
  border: 0 !important;
  margin: 0 !important;
}

.category-draggable-accordion .MuiAccordionDetails-root .draggable-item {
  padding: 0 20px !important;
}

.category-draggable-accordion li.draggable-item.dragging {
  background: #e1f0fc;
  border-color: #e1f0fc;
}

.category-draggable-accordion .drag-handler {
  display: flex;
  margin-right: 14px;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  flex-shrink: 0;
}

.category-draggable-accordion .drag-handler img {
  display: block;
  height: 30px;
  width: 30px;
}

.category-draggable-accordion .drag-handler img {
  height: 24px;
  width: 24px;
}

.category-draggable-accordion .drag-handler {
  height: 28px;
  margin-right: 6px;
  width: 28px;
}

.category-draggable-accordion li.draggable-item.active {
  background: #e1f0fc;
}

.category-draggable-accordion li.draggable-item a {
  text-decoration: none;
  padding: 3px 0 3px 5px;
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
  max-width: calc(100% - 128px);
}

.category-draggable-accordion li.draggable-item .right {
  margin-left: auto;
  flex-shrink: 0;
  padding: 5px;
  /* background: #ffffff; */
  border-radius: 0 6px 6px 0;
  opacity: 0;
  position: relative;
  z-index: 1;
}

.category-draggable-accordion li.draggable-item:hover .right {
  opacity: 1;
}

.category-draggable-accordion .icon-btn {
  height: 30px;
  width: 30px;
}

.category-draggable-accordion li.draggable-item.active .icon-btn:hover,
.category-draggable-accordion li.draggable-item.active .icon-btn:focus {
  background: #fff;
}

.category-draggable-accordion .icon-btn img {
  height: 15px;
  width: 15px;
}

.category-draggable-accordion.category-item-list .drag-handler img {
  height: 18px;
  width: 18px;
}

.category-draggable-accordion.category-item-list .drag-handler {
  height: 20px;
  margin-right: 5px;
  width: 20px;
}

.category-draggable-accordion.category-item-list .draggable-item {
  padding: 5px 10px;
  margin-bottom: 10px;
  min-height: 36px;
}

.category-draggable-accordion.category-item-list .icon-btn {
  height: 24px;
  width: 24px;
}

.category-draggable-accordion.category-item-list .icon-btn img {
  height: 14px;
  width: 14px;
}

.category-draggable-accordion.category-item-list .item-label {
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  padding: 0 4px 0 0;
  line-height: 16px;
  max-width: calc(100% - 144px);
}

.category-draggable-accordion.category-item-list .item-label span {
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
}

.category-draggable-accordion.category-item-list .item-label small {
  letter-spacing: 0.5px;
}

.category-draggable-accordion .item-label {
  font-size: 16px;
  display: block;
  flex-grow: 1;
  padding-right: 10px;
  line-height: 19px;
  font-weight: 600;
  color: #737373;
}

.category-draggable-accordion .right {
  margin-left: auto;
  display: block;
  display: flex;
  align-items: center;
}

.category-draggable-accordion .right .MuiButton-root {
  margin-left: 10px;
}

.category-draggable-accordion.category-item-list .right .MuiButton-root {
  margin-left: 6px;
}

.category-draggable-accordion .right .primary-switch {
  margin-top: -5px !important;
}

.category-draggable-accordion .action-menu-btn {
  display: none;
}

/* primary-dialog
--------------------------------------------------------------------------------------------------------*/
.primary-dialog .MuiDialog-paper.MuiDialog-paperWidthXs {
  max-width: 490px;
}

.primary-dialog .MuiDialog-paper.MuiDialog-paperWidthMd {
  max-width: 840px;
}

.primary-dialog .MuiDialog-paper.MuiDialog-paperWidthLg {
  max-width: 870px;
}

.primary-dialog .MuiDialog-paper.MuiDialog-paperWidthXl {
  max-width: 980px;
}

.primary-dialog.faq-dialog .MuiDialog-paper.MuiDialog-paperWidthXl {
  max-width: 1200px;
}

.primary-dialog.xxl .MuiDialog-paper {
  max-width: 1060px;
}

.primary-dialog .MuiDialog-paper {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
}

.primary-dialog.full-height .MuiDialog-paper {
  height: 100%;
}

.primary-dialog .MuiDialogTitle-root {
  display: flex;
  padding: 10px 10px 15px 20px;
  align-items: center;
  justify-content: space-between;
}

.primary-dialog .MuiDialogTitle-root h5 {
  color: #737373;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

.primary-dialog .MuiDialogTitle-root h6 {
  color: #737373;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.primary-dialog .MuiDialogTitle-root .MuiButton-root {
  border: none;
  margin-left: auto;
}

.primary-dialog .MuiDialogContent-root {
  padding: 6px 20px 20px !important;
}

.primary-dialog.details-dialog .MuiDialogTitle-root {
  padding: 14px 10px 6px 30px;
}

.primary-dialog.details-dialog .MuiDialogContent-root {
  padding: 15px 30px 0 !important;
}

.primary-dialog .MuiDialogContent-root .primary-switch {
  margin: -10px 0 -5px;
}

.primary-dialog .MuiDialogActions-root {
  padding: 10px 20px 20px;
  justify-content: flex-start;
}

.detail-info {
  height: calc(100% - 30px);
  margin-bottom: 30px;
}

.detail-info span,
.detail-info strong:not(.badge) {
  font-size: 15px;
  line-height: 19px;
  color: #737373;
  display: block;
}

.detail-info span {
  padding-bottom: 6px;
}

.detail-info strong:not(.badge) {
  font-weight: 600;
}

.detail-info .badge {
  display: inline-flex;
}

.primary-dialog.table-list-dialog .MuiDialogActions-root {
  padding-top: 0;
  padding-bottom: 20px;
}

.element-center {
  display: flex;
  width: 100%;
  justify-content: center;
}

/* barcode-dialog
--------------------------------------------------------------------------------------------------------*/
.barcode-dialog .MuiDialogContent-root {
  display: flex;
}

.barcode-dialog .MuiDialogContent-root .barcode-template {
  /* max-width: calc(50% - 40px); */
  flex-basis: calc(50% - 40px);
  flex-grow: 1;
  flex-shrink: 0;
  margin: 0 10px 10px;
  height: 100%;
}

.owl-carousel .owl-item img {
  width: unset !important;
}

.barcode-template > p {
  text-align: center;
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 10px;
  font-weight: 500;
  color: #737373;
  display: flex;
  align-items: center;
  justify-content: center;
}

.barcode-template .card {
  box-shadow: none;
  border: 1px solid #dde3ec;
  border-radius: 6px;
  position: relative;
  margin-bottom: 30px;
}

.barcode-template.one .card {
  width: 100%;
  margin: 0 auto 30px;
  padding: 48px 30px 52px;
  min-height: 585px;
}

.barcode-template.one strong {
  font-size: 32px;
  line-height: 35px;
  color: #231f20;
  font-weight: 700;
  max-width: 148px;
  margin: 0 auto;
  display: block;
  text-align: center;
  padding-bottom: 2px;
}

.barcode-template.one strong span {
  color: #70b5e4;
}

.barcode-template.one .card > span {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 2px;
  text-align: center;
  display: block;
  padding-bottom: 8px;
  color: #231f20;
}

.barcode-template.one .card > img:not(.barcode) {
  display: block;
  width: 28px;
  margin: 0 auto 30px;
}

.barcode-template .barcode {
  display: block;
  max-width: 230px;
  max-height: 230px;
  margin: 0 auto;
}

.barcode-template.one .barcode {
  margin-bottom: 46px;
}

.barcode-template .table-number {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #231f20;
  text-align: center;
  display: block;
}

.barcode-template.one .table-number {
  padding-bottom: 8px;
}

.powered-by-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.powered-by-text img {
  display: block;
  width: 28px;
}

.powered-by-text small {
  font-size: 11px;
  line-height: 16px;
  display: block;
  color: #383435;
  font-weight: 500;
  padding-right: 4px;
}

.qr-code-restaurant-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-code-restaurant-logo img {
  display: block;
  height: auto;
  max-width: 100px;
}

.barcode-template.two .card {
  height: 358px;
  width: 100%;
  margin: 0 auto 0;
  border: none;
}

.barcode-template.two strong {
  display: block;
  max-width: 120px;
  font-weight: 700;
  font-size: 28px;
  line-height: 24px;
  color: #231f20;
  text-align: center;
  margin: 0 auto;
  padding-top: 23px;
}

.barcode-template.two strong span {
  color: #70b5e4;
}

.barcode-template.two strong.top {
  transform: rotateX(180deg) rotateY(180deg);
  padding-top: 23px;
}

.barcode-template.two .barcode {
  margin-bottom: 23px;
}

.barcode-template.two .powered-by-text-left {
  position: absolute;
  top: calc(50% + 28px);
  left: -16px;
  transform: rotate(90deg) translatex(-50%);
  transform-origin: center;
}

.barcode-template.two .powered-by-text-right {
  position: absolute;
  top: calc(50% + 28px);
  right: -16px;
  transform: rotate(90deg) rotateX(180deg) rotateY(180deg) translatex(50%);
  transform-origin: center;
}

.barcode-template.two .outer {
  min-height: 585px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dde3ec;
}

.barcode-template.two .table-number {
  padding-bottom: 8px;
  padding-top: 20px;
}

.barcode-template.three .card > div:nth-child(2) {
  margin-top: 0px;
}
.barcode-template.three .card {
  height: 358px;
  margin: 0 auto 0;
  border: none;
}

.barcode-template.three strong {
  display: block;
  width: 2in;
  font-weight: 600;
  font-size: 40px;
  line-height: 33px;
  color: #231f20;
  text-align: center;
  margin: 0 auto;
}

.barcode-template.three strong span {
  color: #70b5e4;
}

.barcode-template.three strong.top {
  transform: rotateX(180deg) rotateY(180deg);
}

.barcode-template.three .barcode {
  margin-bottom: 23px;
}

.barcode-template.three .powered-by-text-top {
  color: #008cff;
  transform: rotate(180deg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 80px;
}
.barcode-template.three .powered-by-text-top > small {
  font-size: 10px;
  color: '#008cff';
}
.barcode-template.three .powered-by-text-top > div > img {
  height: 50px;
}
.barcode-template.three .powered-by-text-bottom {
  color: #008cff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0px 80px;
}
.barcode-template.three .powered-by-text-bottom > small {
  font-size: 10px;
  color: '#008cff';
}
.barcode-template.three .powered-by-text-bottom > div > img {
  height: 50px;
}
.barcode-template.three .outer {
  min-height: 585px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #dde3ec;
}

.barcode-template.three .table-number {
  padding-bottom: 8px;
  padding-top: 20px;
}
/* .barcode-template .left {
  position: relative;
  width: 140px;
  position: absolute;
  left: 10px;
  top: 14px;
} */
/* .barcode-template .left .arrow {
  width: 50px;
  display: block;
  position: absolute;
  top: 33px;
  right: -34px;
} */
/* .barcode-template .left img {
  display: block;
}
.barcode-template .left img {
  width: 140px;
}
.barcode-template .left img.logo {
  width: 121px;
  margin: 3px auto 7px;
}
.barcode-template .left span {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: #737373;
  padding-left: 9px;
}
.barcode-template .right {
  padding: 0 8px 0 146px;
}
.barcode-template .right img {
  display: block;
  max-width: 135px;
  margin: 20px auto 4px;
}
.barcode-template .right span {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
  font-weight: 500;
}
.barcode-template .right strong {
  font-size: 18px;
  line-height: 26px;
  padding-bottom: 14px;
  font-weight: 700;
  display: block;
  padding-top: 66px;
  text-align: center;
  color: #404040;
}
.barcode-template .right strong small {
  font-size: 14px;
  line-height: 20px;
  display: block;
  text-align: center;
  font-weight: 400;
  color: #737373;
}
.barcode-template .right h4 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: -0.48px;
  text-align: center;
  color: #737373;
  padding-top: 48px;
  padding-bottom: 10px;
} */

.owl-theme .owl-nav {
  text-align: unset !important;
}

.owl-theme .owl-nav [class*='owl-']:hover {
  background: unset !important;
}

.owl-nav .owl-prev {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 310px !important;
  left: -20px !important;
}

.owl-nav .owl-prev img {
  transform: rotate(90deg);
}

.owl-nav .owl-next {
  height: 47px;
  position: absolute;
  width: 26px;
  cursor: pointer;
  top: 310px !important;
  right: -20px;
}

.owl-nav .owl-next img {
  transform: rotate(270deg);
}

.owl-nav .owl-prev img,
.owl-nav .owl-next img {
  width: 100%;
}

.barcode-template .MuiButton-root {
  min-width: 180px;
  margin: 0 auto;
}

.barcode-template .MuiButton-root img {
  margin-right: 10px;
}

.barcode-template .inner img.barcode {
  width: 85px;
}

.barcode-template .inner {
  height: 200px;
  width: 200px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #dde3ec;
  padding: 45px 10px 0;
  position: relative;
}

.barcode-template .inner .barcode-wrapper {
  width: 90px;
  margin: 6px auto 10px;
}

.barcode-template .inner .barcode-wrapper svg {
  display: block;
}

.barcode-template .inner .circular-text {
  position: absolute;
  top: -7px;
  left: -7px;
}

.barcode-template .inner img {
  display: block;
  margin: 0 auto;
}

.barcode-dialog a {
  display: inline-block;
  text-align: center;
  padding-left: 10px;
  margin-bottom: -3px;
}

.barcode-template .inner .arrow {
  position: absolute;
  top: 109px;
  right: 25px;
}

.barcode-template .inner span {
  font-size: 12px;
  line-height: 20px;
  display: block;
  padding-bottom: 10px;
  color: #737373;
  font-weight: 600;
  text-align: center;
}

.barcode-template .inner img.logo {
  height: 18px;
}

.barcode-template .inner small {
  font-size: 8px;
  line-height: 14px;
  display: block;
  text-align: center;
  color: #737373;
  font-weight: 600;
}

/* create-password
--------------------------------------------------------------------------------------------------------*/
.create-password {
  padding: 40px 30px;
  min-height: 350px;
}

.form-h4 {
  font-size: 20px;
  padding-bottom: 30px;
  margin-bottom: 20px;
}

.create-password h6 {
  padding-bottom: 40px;
}

.create-password .MuiButton-root {
  margin: 30px 0 20px;
  width: 100%;
}

/* menu-items-dialog
--------------------------------------------------------------------------------------------------------*/
.menu-items-dialog .btn-list {
  margin-bottom: 10px;
}

.menu-items-wrapper {
  display: flex;
  padding: 0 10px;
}

.menu-items-wrapper.empty {
  min-height: 343px;
  padding-bottom: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.menu-items-wrapper.empty span {
  font-size: 15px;
  line-height: 22px;
  color: #737373;
  text-align: center;
  padding-bottom: 18px;
}

.menu-items-wrapper.empty span strong {
  display: block;
  text-align: center;
  font-weight: 600;
}

.menu-items-wrapper.empty a {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}

.menu-items-wrapper > .left {
  background: #fcfcfc;
  max-width: 400px;
  border: 1px solid #e4e4e4;
  border-radius: 6px 0px 0px 6px;
  flex-basis: 400px;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 13px 20px 18px;
}

.menu-items-wrapper > .right {
  padding: 13px 20px 8px;
  max-width: calc(100% - 400px);
  flex-basis: calc(100% - 400px);
  flex-grow: 0;
  flex-shrink: 0;
  border: 1px solid #e4e4e4;
  border-radius: 0px 6px 6px 0px;
}

.menu-items-wrapper .items-top {
  display: flex;
  margin-bottom: 14px;
  align-items: center;
  justify-content: space-between;
}

.menu-items-wrapper .items-top p {
  font-size: 16px;
  line-height: 19px;
  color: #737373;
  font-weight: 600;
}

.menu-items-wrapper .items-top .MuiButton-root {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  min-width: auto;
  min-height: auto;
}

.menu-items-wrapper .items-top .MuiButton-root img {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

/* switch-tabs
--------------------------------------------------------------------------------------------------------*/
.switch-tabs .MuiTabs-flexContainer {
  justify-content: center;
  position: relative;
  z-index: 1;
  width: max-content;
  border-radius: 24px;
  border: 1px solid #d8d8d8;
  margin: 0 auto;
}

.switch-tabs.MuiTabs-root {
  margin: 0 0 25px;
}

.switch-tabs .MuiTab-root {
  min-width: 200px;
  border: 1px solid transparent;
  margin: -1px;
  color: #737373;
  text-transform: none;
  border-radius: 24px;
  transition: color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.switch-tabs .MuiTab-root.Mui-selected {
  color: #fff;
  border-color: #008cff;
  transition: border 800ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.switch-tabs .MuiTabs-indicator {
  height: 100%;
  border-radius: 24px;
  background: #008cff;
}

/* primary-tabs
--------------------------------------------------------------------------------------------------------*/
.primary-tabs.MuiTabs-root {
  margin: 0 15px;
  min-height: auto;
  border-bottom: 1px solid #dde3ec;
}

.mobile-menu-preview .primary-tabs.MuiTabs-root {
  margin: 0 10px;
}

.primary-tabs .MuiTab-root {
  font-size: 16px;
  padding: 20px 15px;
  min-height: auto;
  line-height: 14px;
  font-weight: 500;
  color: #a6a6a7;
  opacity: 1;
  letter-spacing: 0.48px;
}

.mobile-menu-preview .primary-tabs .MuiTab-root {
  font-size: 14px;
  padding: 15px 4px;
  line-height: 16px;
  color: #a6a6a7;
  opacity: 1;
  letter-spacing: 0.48px;
  min-width: 74px;
}

.primary-tabs .MuiTab-root.Mui-selected {
  color: #3d8af7;
}

.tab-content img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.primary-tabs .MuiTabs-indicator {
  background-color: #3d8af7;
}

/* secondary-tabs
--------------------------------------------------------------------------------------------------------*/
.secondary-tabs.MuiToggleButtonGroup-root {
  display: flex;
  padding: 15px 0;
  margin: 0 15px;
  overflow-x: auto;
  scrollbar-width: none;
}

.mobile-menu-preview .secondary-tabs.MuiToggleButtonGroup-root {
  padding: 10px 0;
  margin: 0 10px;
}

.secondary-tabs.MuiToggleButtonGroup-root::-webkit-scrollbar {
  display: none;
}

.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root {
  padding: 7px 10px;
  border-radius: 50px !important;
  border: 1px solid transparent;
  text-transform: unset;
  font-weight: 400;
  letter-spacing: 0.48px;
  transition: all 0.3s ease;
  color: #8a9fb0;
  font-size: 13px;
  line-height: 14px;
  flex-shrink: 0;
}

.mobile-menu-preview
  .secondary-tabs.MuiToggleButtonGroup-root
  .MuiButtonBase-root {
  padding: 6px 8px;
  font-size: 11px;
  line-height: 13px;
}

.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root:first-child {
  margin-left: 0 !important;
}

.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root:last-child {
  margin-right: 0 !important;
}

.secondary-tabs.MuiToggleButtonGroup-root .MuiButtonBase-root.Mui-selected {
  border-color: #bbd5eb;
  background: transparent;
  padding-left: 14px;
  padding-right: 14px;
  margin: 0 10px;
}

.mobile-menu-preview
  .secondary-tabs.MuiToggleButtonGroup-root
  .MuiButtonBase-root.Mui-selected {
  margin: 0 8px;
  padding-left: 10px;
  padding-right: 10px;
}

/* mobile-menu-preview
--------------------------------------------------------------------------------------------------------*/
.mobile-menu-preview {
  background-image: url('./assets/images/mobile-frame-2.png');
  padding: 112px 35px 35px;
  height: 758px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.mobile-menu-preview .restaurant-info {
  margin-top: -61px;
  margin-bottom: 24px;
}

.mobile-menu-preview .restaurant-info strong {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: #737373;
  display: block;
  text-align-last: center;
}

.mobile-menu-preview .restaurant-info span {
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  text-align: center;
  display: block;
}

.mobile-menu-preview .restaurant-info span,
.mobile-menu-preview .restaurant-info strong {
  max-width: 216px;
  margin: 0 auto;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.mobile-menu-preview .inner {
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.01);
  overflow-y: auto;
  scrollbar-width: none;
  height: 100%;
}

.mobile-menu-preview .inner::-webkit-scrollbar {
  display: none;
}

.mobile-menu-preview .menu-item-list {
  padding: 0 10px 1px;
}

.mobile-menu-preview .menu-item-list li {
  display: flex;
  margin-bottom: 10px;
}

.mobile-menu-preview .menu-item-list li span {
  font-size: 10px;
  line-height: 15px;
}

.mobile-menu-preview .menu-item-list li .item-details {
  color: #a6a6a7;
  padding-right: 10px;
}

.mobile-menu-preview .menu-item-list li .item-details strong {
  display: block;
  color: #5e6266;
  font-weight: 600;
  padding-bottom: 2px;
}

.mobile-menu-preview .menu-item-list li .item-price {
  color: #a6a6a7;
  font-weight: 600;
  margin-left: auto;
}

.mobile-menu-preview .category-accordion {
  margin: 0 10px;
}

.mobile-menu-preview .category-accordion .MuiPaper-root {
  margin: 0;
  box-shadow: none;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}

.mobile-menu-preview .category-accordion .MuiPaper-root:before {
  display: none;
}

.mobile-menu-preview .category-accordion .MuiPaper-root + .MuiPaper-root {
  margin-top: 10px;
}

.mobile-menu-preview .category-accordion .MuiAccordionSummary-root {
  min-height: 36px;
}

.mobile-menu-preview
  .category-accordion
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content {
  margin: 7px 0;
  color: #737373;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  justify-content: space-between;
}

/* forgot-passsword
--------------------------------------------------------------------------------------------------------*/
.card.bordered.forgot-password,
.otp-verification.card.bordered {
  margin-top: 34px;
  min-height: 585px;
}

.forgot-password .animation-wrapper > div,
.otp-verification .animation-wrapper > div {
  background: transparent;
}

.forgot-password .animation-wrapper {
  margin-bottom: 25px;
}

.forgot-password h4,
.otp-verification h4,
.create-password h4 {
  font-weight: 600;
  color: #737373;
  text-align: center;
  padding-bottom: 6px;
}

.otp-verification h3 {
  font-weight: 600;
  color: #737373;
  text-align: center;
  padding-bottom: 6px;
  margin-bottom: 25px;
}

.forgot-password h6,
.otp-verification h6,
.create-password h6 {
  max-width: 307px;
  text-align: center;
  margin: 0 auto;
  color: #737373;
  padding-bottom: 50px;
}

.forgot-password .MuiButton-root {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.forgot-password a {
  font-size: 14px;
  text-align: center;
  display: block;
}

/* otp
--------------------------------------------------------------------------------------------------------*/
.otp-verification.card.bordered {
  margin-top: 34px;
  min-height: 585px;
}

.enable-two-fa {
  color: #616161;
}

.forgot-password .animation-wrapper > div,
.otp-verification .animation-wrapper > div {
  background: transparent;
}

.forgot-password .animation-wrapper {
  margin-bottom: 25px;
}

.forgot-password h4,
.create-password h4 {
  font-weight: 600;
  color: #737373;
  text-align: center;
  padding-bottom: 6px;
}

.otp-verification h3 {
  font-weight: 600;
  color: #737373;
  text-align: center;
  padding-bottom: 6px;
  margin-bottom: 25px;
}

.forgot-password h6,
.otp-verification h6,
.create-password h6 {
  max-width: 307px;
  text-align: center;
  margin: 0 auto;
  color: #737373;
  padding-bottom: 50px;
}

.forgot-password .MuiButton-root {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.forgot-password a {
  font-size: 14px;
  text-align: center;
  display: block;
}

/* otp-verification
--------------------------------------------------------------------------------------------------------*/
.otp-verification {
  padding-top: 36px;
}

.otp-verification .animation-wrapper {
  margin-bottom: 34px;
}

.otp-verification .otp-input-wrapper {
  margin-bottom: 30px;
}

.otp-verification h6 {
  padding-bottom: 32px;
}

.now-wrap-categories {
  overflow: visible !important;
  white-space: unset !important;
  text-overflow: unset !important;
}

.wrap-comments {
  overflow: hidden !important;
  white-space: unset !important;
  text-overflow: ellipsis !important;
}

.otp-input-wrapper .otp-input input {
  height: 50px;
  transition: all 0.3s ease;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
  font-weight: 600;
  width: 50px !important;
  border: none;
  border-bottom: 2px solid #d8d8d8;
}

.otp-input-wrapper .otp-input input:focus {
  border-bottom-color: #008cff;
}

.otp-input-wrapper .otp-input {
  margin: 0 10px;
}

.otp-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-verification .MuiButton-root {
  margin: auto;
  margin-bottom: 30px;
}

.otp-verification p {
  font-size: 14px;
  line-height: 19px;
  color: #737373;
  text-align: center;
}

.otp-verification p a {
  padding-left: 4px;
}

/* primary-grid
--------------------------------------------------------------------------------------------------------*/
.primary-grid.MuiDataGrid-root {
  border-top: 0;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-cell,
.primary-grid.MuiDataGrid-root .MuiDataGrid-columnHeaders {
  border-bottom-color: #d8d8d8;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-overlay {
  align-items: flex-start;
  padding-top: 40px;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-cell a {
  display: block;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  color: #737373;
  line-height: 47px;
  white-space: nowrap;
  overflow: hidden;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-cell a.status-link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-row:hover,
.primary-grid.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background: #f9f9f9;
}

.primary-grid.tabs-grid.MuiDataGrid-root .MuiDataGrid-row:hover,
.primary-grid.tabs-008cffd.MuiDataGrid-root .MuiDataGrid-row.Mui-hovered {
  background: rgba(0, 140, 255, 0.1);
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-row.Mui-selected,
.primary-grid.MuiDataGrid-root .MuiDataGrid-row.Mui-selected:hover {
  background: rgba(0, 140, 255, 0.1);
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  display: none;
}

.show-resize.primary-grid.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  display: flex;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-columnHeader,
.primary-grid.MuiDataGrid-root .MuiDataGrid-cell {
  padding: 0 15px;
}

.primary-grid.MuiDataGrid-root
  .MuiDataGrid-columnHeader.MuiDataGrid-columnHeaderCheckbox {
  padding: 0;
}

.primary-grid.MuiDataGrid-root .primary-checkbox {
  margin: 0;
}

.include-pos-checkbox {
  font-size: 16px !important;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle,
.primary-grid.MuiDataGrid-root .MuiDataGrid-cellContent,
.primary-grid.MuiDataGrid-root .MuiDataGrid-cell {
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #737373;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-cellContent,
.primary-grid.MuiDataGrid-root .MuiDataGrid-cell {
  font-weight: 400;
}

.primary-grid.MuiDataGrid-root .MuiDataGrid-cell {
  min-height: 48px !important;
}

.primary-grid.MuiDataGrid-root .MuiCheckbox-root .MuiSvgIcon-root {
  height: 24px;
  width: 24px;
  fill: #d8d8d8;
}

.primary-grid.MuiDataGrid-root .MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  fill: #008cff;
}

.primary-grid.MuiDataGrid-root .username {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.primary-grid.MuiDataGrid-root .username .icon {
  height: 32px;
  width: 32px;
  border-radius: 50px;
  background: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary-grid.MuiDataGrid-root .username .icon img {
  display: block;
  height: 18px;
  width: 18px;
}

.primary-grid.MuiDataGrid-root .username span {
  display: block;
}

.primary-grid.MuiDataGrid-root .username strong {
  font-weight: 500;
  color: #212121;
  display: block;
}

.badge {
  height: 28px;
  text-align: center;
  min-width: 84px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  font-size: 12px;
  line-height: 18px;
  border-radius: 20px;
  font-weight: 600;
}

.badge.success {
  background: #90c95a;
  color: #fff;
  padding: initial;
}

.badge.default {
  background: #e5e4e4;
  color: #737373;
  padding: initial;
}

.badge.danger {
  background: #db5454;
  color: #fff;
  padding: initial;
}

.text-green {
  color: green;
}

.text-blue {
  color: #008cff;
}

.text-red {
  color: #db5454;
}

.icon-btn.no-border {
  border: none;
}

.primary-grid .icon-btn,
.primary-grid.MuiDataGrid-root .MuiDataGrid-cell a.icon-btn {
  height: 30px;
  display: flex;
  width: 30px;
  background: transparent;
}

.primary-grid .icon-btn img {
  height: 24px;
  width: 24px;
}

.icon-btn.small img,
.primary-grid .icon-btn.small img {
  height: 16px;
  width: 16px;
}

.icon-btns-span {
  font-size: 13px;
  padding: 4px;
  color: #737373;
}

/* .text-icon-btns{
  background: #fff !important;
  border-color: #008cff !important;
} */
.primary-grid .action-buttons {
  display: flex;
}

.primary-grid .action-buttons .MuiButton-root {
  margin: 0 5px;
}

/* .primary-grid.MuiDataGrid-root .MuiCheckbox-root svg {
  display: none;
} */
/* .primary-grid.MuiDataGrid-root .MuiCheckbox-root {
  height: 38px;
  min-width: 38px;
  background-image: url('./assets/images/ic-unchecked.svg');
  background-repeat: no-repeat;
  background-position: center;
}
.primary-grid.MuiDataGrid-root .MuiCheckbox-root.Mui-checked {
  background-image: url('./assets/images/ic-checked.svg');
} */

/* pagination-wrapper
--------------------------------------------------------------------------------------------------------*/
.pagination-wrapper {
  display: flex;
  padding: 16px 5px 16px 24px;
  align-items: center;
  justify-content: space-between;
}

.pagination-wrapper .total-pages {
  display: flex;
  align-items: center;
}

.pagination-wrapper .total-pages .form-group {
  margin: 0 12px;
  min-width: 65px;
}

.pagination-wrapper .form-group .MuiTextField-root .MuiOutlinedInput-input {
  padding: 8px 0 8px 7px;
  font-size: 15px;
  line-height: 22px;
}

.pagination-wrapper
  .form-group
  .MuiTextField-root
  .MuiOutlinedInput-notchedOutline {
  top: 0;
}

.pagination-wrapper
  .form-group
  .MuiTextField-root
  .MuiOutlinedInput-notchedOutline
  legend {
  display: none;
}

.pagination-wrapper .form-group .MuiSelect-icon {
  background-image: url('./components/Designer/assets/images/ic-down-fill.svg');
  background-size: 15px 10px;
}

.pagination-wrapper .total-pages span {
  font-size: 14px;
  line-height: 21px;
  color: #737373;
}

.pagination-wrapper .MuiPaginationItem-root {
  min-width: 36px;
  transition: all 0.3s ease;
  height: 36px;
  margin: 0 4px;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.pagination-wrapper .MuiPaginationItem-root.Mui-selected {
  background: #008cff;
  color: #fff;
}

.pagination-wrapper .MuiPaginationItem-root.Mui-selected:hover {
  background: #008cff;
}

.pagination-wrapper
  .MuiPagination-ul
  li:first-child
  .MuiButtonBase-root
  svg
  path,
.pagination-wrapper
  .MuiPagination-ul
  li:last-child
  .MuiButtonBase-root
  svg
  path {
  display: none;
}

.pagination-wrapper .MuiPagination-ul li:first-child {
  margin-right: 6px;
}

.pagination-wrapper .MuiPagination-ul li:last-child {
  margin-left: 6px;
}

.pagination-wrapper .MuiPagination-ul li:first-child .MuiButtonBase-root svg {
  background-image: url('./components/Designer/assets/images/ic-prev.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.pagination-wrapper .MuiPagination-ul li:last-child .MuiButtonBase-root svg {
  background-image: url('./components/Designer/assets/images/ic-next.svg');
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

/* table-list
--------------------------------------------------------------------------------------------------------*/
.table-list {
  margin-top: -5px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  min-height: 200px;
  padding: 20px 20px 10px;
  overflow-y: auto;
  max-height: calc(100vh - 324px);
}

.table-list .form-group {
  margin-bottom: 10px;
}

.table-list .form-group .MuiTextField-root .MuiInputBase-inputAdornedStart {
  padding-right: 10px;
  width: auto;
  flex-grow: 1;
}

.primary-checkbox.select-all {
  margin-top: -20px;
  margin-bottom: 16px;
  margin-left: -9px;
}

.table-retrive-switch {
  margin-top: -20px !important;
  margin-bottom: 16px !important;
  margin-left: -9px !important;
}

.table-retrive-switch span {
  font-weight: 600 !important;
  font-size: 14px !important;
}

.table-retrive-switch .MuiSwitch-root.MuiSwitch-sizeMedium {
  order: 2 !important;
}

/* no-items
--------------------------------------------------------------------------------------------------------*/
.card.no-items {
  min-height: 610px;
  padding-top: 50px;
  margin-bottom: 30px;
  padding-bottom: 50px;
}

.card.no-items .inner {
  max-width: 355px;
  margin: 0 auto;
}

.card.no-items .inner h6 {
  color: #737373;
  font-weight: 600;
  text-align: center;
  padding-bottom: 15px;
}

.card.no-items .inner > p {
  font-size: 14px;
  line-height: 21px;
  color: #737373;
  text-align: center;
  padding-bottom: 15px;
}

.card.no-items .inner .btn-list {
  justify-content: center;
}

.card.no-items .inner .file-upload {
  min-height: unset;
  height: 148px;
  margin-bottom: 25px;
  padding: 10px;
}

.card.no-items .inner .file-upload .uploaded-image {
  max-height: 100%;
  display: block;
  margin: 0;
  max-width: 100%;
}

.card.no-items .inner .file-upload:hover {
  background: #fff;
}

/* pos-config
--------------------------------------------------------------------------------------------------------*/
.pos-config {
  margin-top: 14px;
  margin-bottom: 30px;
}

.pos-config .top {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.pos-config .top h6 {
  color: #737373;
  font-weight: 600;
}

.pos-config ul li {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f3f3f3;
  border-radius: 8px;
}

.config-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  background: #f3f3f3;
  border-radius: 8px;
}

.pos-config ul li .icon-btn {
  height: 48px;
  width: 48px;
  flex-shrink: 0;
}

.pos-config ul li .icon-btn:hover,
.pos-config ul li .icon-btn:focus {
  background: #fff;
  border-color: #008cff;
}

.pos-config ul li .form-group {
  margin: 0 10px 0 0;
  flex-grow: 1;
}

.value-btn {
  margin-top: 10px !important;
}

.config-row .form-group {
  margin: 0 10px 0 0;
  flex-grow: 1;
}

/* Tab Details Page
--------------------------------------------------------------------------------------------------------*/
.tab-detail-card + .tab-detail-card {
  margin-top: 30px;
}

.tab-detail-summary {
  display: flex;
  align-items: center;
}

.tab-detail-summary h1 {
  display: flex;
  align-items: center;
  font-size: 40px;
  line-height: 46px;
  font-weight: 600;
  color: #008cff;
}

.tab-detail-summary h1 em {
  margin-left: 15px;
}

.tab-detail-summary h1 em .badge {
  min-width: auto;
  padding: 0 10px;
  height: 20px;
}

.tab-detail-summary h1 em .badge + .badge {
  margin-top: 5px;
}

.tab-detail-summary ul {
  display: flex;
}

.tab-detail-summary ul li {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #000;
}

.tab-detail-summary-data {
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  color: #737373;
  margin-left: 20px;
}

.tab-detail-summary-data span {
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #000000;
}

.tab-detail-summary-right {
  margin-left: auto;
  display: flex;
}

.tab-detail-summary-right .icon {
  display: block;
}

.tab-detail-summary-right div {
  padding-left: 15px;
}

.tab-detail-summary-right h2 {
  display: flex;
  color: #737373;
  font-size: 24px;
  line-height: 30px;
  justify-content: flex-end;
}

.tab-detail-summary-right h2 span {
  color: #000;
  font-weight: 600;
}

.tab-detail-summary-right h5 {
  display: flex;
  color: #737373;
  font-size: 14px;
  line-height: 20px;
  justify-content: flex-end;
}

.tab-detail-summary-right h5 span {
  color: #000;
  font-weight: 600;
}

.tab-details-count-ele {
  display: flex;
  align-items: center;
}

.tab-detail-card-header {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
}

.tab-detail-card-header h6 {
  margin-right: 30px;
}

.tab-detail-card-header ul {
  display: flex;
}

.tab-detail-card-header ul li + li {
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #000;
}

.star-rating {
  display: flex !important;
}

.star-rating img {
  display: block;
  width: 20px;
  margin: 5px;
}

.no-feedback-data {
  opacity: 50%;
  display: block;
  text-align: center;
}

.star-rating img + img {
  margin-left: 3px;
}

.grid-length-count {
  display: inline;
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
  color: #000000;
}

.tab-detail-card .MuiStepper-root .MuiStepLabel-root {
  padding-top: 6px;
  padding-bottom: 6px;
}

.tab-detail-card .MuiStepper-root .MuiStepLabel-label {
  color: #000000;
  font-weight: 400;
}

.tab-detail-card .MuiStepLabel-labelContainer .MuiTypography-caption {
  color: #737373;
}

.tab-detail-card .MuiStepper-root .MuiStepConnector-line {
  margin-top: -15px;
  margin-bottom: -15px;
  min-height: 30px;
}

.tab-detail-card .MuiStepper-root .Mui-disabled .MuiSvgIcon-root circle {
  color: #ffffff;
}

.tab-detail-card .MuiStepper-root .Mui-disabled .MuiSvgIcon-root text {
  fill: #000000;
}

/* loader
--------------------------------------------------------------------------------------------------------*/
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  display: flex;
  transition: all 0.3s ease;
  align-items: center;
  justify-content: center;
}

body.show-loader {
  overflow: hidden;
}

.show-loader .loader-wrapper {
  visibility: visible;
  opacity: 1;
}

.loader {
  width: 80px;
  height: 80px;
  font-size: 80px;
  position: relative;
  display: inline-block;
}

.loader:before,
.loader:after {
  content: '';
  top: 0;
  display: block;
  width: 1em;
  height: 1em;
  position: absolute;
  border-width: 0.5em;
  border-style: double;
  border-color: transparent;
  box-sizing: border-box;
  border-radius: 1em;
  -webkit-animation: loader 2s infinite;
  animation: loader 2s infinite;
}

.loader:after {
  left: 0;
  border-left-color: #008cff;
}

.loader:before {
  right: 0;
  border-right-color: #008cff;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

@-webkit-keyframes loader {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

/* footer
--------------------------------------------------------------------------------------------------------*/
.footer {
  font-size: 12px;
  line-height: 18px;
  color: #666666;
  text-align: center;
  padding-bottom: 30px;
}

.footer .primary-link {
  font-weight: 300;
}

/* Responsive Media Queries
--------------------------------------------------------------------------------------------------------*/
@media (min-width: 1200px) {
  .sidebar .logo {
    height: 80px;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
  }

  .sidebar .logo img {
    transition: all 0.3s ease;
  }

  .sidebar .logo img.mobile-logo {
    position: absolute;
    top: 28px;
    left: 18px;
    opacity: 0;
    visibility: hidden;
  }

  .sidebar-toggle .sidebar:not(:hover) .logo img:not(.mobile-logo) {
    opacity: 0;
    visibility: hidden;
  }

  .sidebar-toggle .sidebar:not(:hover) {
    width: 80px;
  }

  .sidebar-toggle .sidebar:not(:hover) .main-navigation > li > a,
  .sidebar-toggle .sidebar:not(:hover) .MuiAccordionSummary-root {
    padding-left: 20px;
    color: transparent;
  }

  .sidebar-toggle .sidebar:not(:hover) .logo {
    padding: 20px 13px 20px 16px;
  }

  .sidebar-toggle .sidebar:not(:hover) .logo img.mobile-logo {
    opacity: 1;
    visibility: visible;
  }

  .sidebar {
    overflow-x: hidden;
    scrollbar-width: thin;
  }

  .sidebar::-webkit-scrollbar {
    appearance: none;
    width: 6px;
    height: 12px;
  }

  .sidebar::-webkit-scrollbar-thumb {
    background-color: rgba(117, 128, 138, 0.6);
    background-clip: padding-box;
    border-radius: 9999px;
    min-height: 32px;
  }

  .sidebar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(117, 128, 138, 0.8);
  }

  .sidebar::-webkit-scrollbar-track {
    background-color: rgb(240, 240, 240);
  }

  .sidebar-toggle .wrapper {
    padding-left: 80px;
  }

  .sidebar-toggle.mouse-enter .wrapper {
    padding-left: 246px;
  }

  .sidebar-toggle .sidebar:not(:hover) .MuiAccordionSummary-content span {
    color: transparent;
  }

  .sidebar-toggle .sidebar:not(:hover) .MuiAccordionDetails-root {
    display: none;
  }

  .sidebar-toggle .sidebar:not(:hover) .MuiAccordionSummary-expandIconWrapper,
  .sidebar-toggle
    .sidebar:not(:hover)
    .MuiAccordionSummary-expandIconWrapper
    img {
    opacity: 0;
  }
}

@media (max-width: 1366px) {
  .page-top.long-right-content {
    flex-wrap: wrap;
    padding-bottom: 0px;
  }

  .page-top.long-right-content .right {
    justify-content: flex-end;
    padding-top: 14px;
    flex-wrap: wrap;
  }

  .page-top.long-right-content .right .MuiButton-root {
    margin-bottom: 14px;
  }
}

/* total earning
--------------------------------------------------------------------------------------------------------*/
.total-earning {
  background: linear-gradient(132deg, #008cff 0%, #5fb6f6 100%);
  height: calc(100% - 30px);
  padding: 62px 30px 44px;
  margin-bottom: 20px;
  height: calc(100% - 20px);
}

.total-earning .oval {
  height: 140px;
  width: 140px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 50px;
}

.total-earning .oval-bg {
  background: #5db6fc;
  height: 140px;
  border-radius: 50%;
  width: 140px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.total-earning .oval .fade {
  position: absolute;
  height: 138px;
  width: 138px;
  top: 1px;
  left: 1px;
  transform-origin: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.15);
  z-index: 0;
  -webkit-animation: ovalfade 4s infinite;
  animation: ovalfade 4s infinite;
  animation-timing-function: linear;
}

.total-earning .oval .fade.two {
  animation-delay: 1.33s;
}

.total-earning .oval .fade.three {
  animation-delay: 2.66s;
}

.total-earning .oval img {
  display: block;
  height: 80px;
  width: 80px;
}

.total-earning span {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 500;
  padding-bottom: 4px;
  text-align: center;
  display: block;
}

.total-earning p {
  font-size: 40px;
  line-height: 60px;
  color: #fff;
  font-weight: 700;
  text-align: center;
}

@keyframes ovalfade {
  33% {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transform: scale(1.6);
    transform: scale(1.2);
  }

  66% {
    background: rgba(255, 255, 255, 0.05);
    -webkit-transform: scale(1.6);
    transform: scale(1.4);
  }

  100% {
    background: rgba(255, 255, 255, 0);
    -webkit-transform: scale(1.6);
    transform: scale(1.6);
  }
}

@-webkit-keyframes ovalfade {
  33% {
    background: rgba(255, 255, 255, 0.1);
    -webkit-transform: skale(360deg);
    transform: skale(1.2);
  }

  66% {
    background: rgba(255, 255, 255, 0.05);
    -webkit-transform: skale(360deg);
    transform: skale(1.4);
  }

  100% {
    background: rgba(255, 255, 255, 0);
    -webkit-transform: skale(360deg);
    transform: skale(1.6);
  }
}

/* chart

--------------------------------------------------------------------------------------------------------*/

.mb-20 {
  margin-bottom: 20px !important;
}

.card.mb-20 {
  height: calc(100% - 20px);
}

.card .card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card .card-header .primary-link {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.card .card-header .form-group {
  margin-bottom: 0;
}

.card.chart .card-header .form-group {
  max-width: 150px;
}

.card.chart
  .card-header
  .form-group
  .MuiTextField-root
  .MuiOutlinedInput-input {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
}

.card.chart .card-header .form-group legend span {
  padding: 0;
}

.card h5 {
  font-weight: 600;
  color: #737373;
}

.card .card-header h5 {
  padding-bottom: 0;
}

.card .card-header .filter {
  display: flex;
  padding-top: 2px;
  flex-shrink: 0;
}

.card .card-header .filter > span {
  font-size: 14px;
  line-height: 20px;
  color: #37474f;
  display: block;
  margin-right: 4px;
}

.card .card-header .filter .MuiTextField-root .MuiSelect-select {
  padding: 0;
  padding-right: 28px;
  font-size: 14px;
  line-height: 20px;
  color: #008cff;
  font-weight: 500;
}

.card .card-header .filter .MuiTextField-root .MuiSelect-icon {
  right: 0;
  top: -2px;
}

.card .card-header .filter .MuiTextField-root .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.primary-menu.filter-menu .MuiMenu-paper {
  width: 140px;
}

/* current-reviews

--------------------------------------------------------------------------------------------------------*/

.current-reviews .card-body {
  display: flex;
  margin-bottom: 32px;
}

.current-reviews .left {
  max-width: 225px;
  flex-basis: 225px;
  margin-right: 20px;
  flex-grow: 1;
  flex-shrink: 0;
}

.current-reviews .right {
  max-width: calc(100% - 245px);
  flex-basis: calc(100% - 245px);
  flex-grow: 1;
  flex-shrink: 0;
}

.current-reviews .right .MuiGrid-container {
  height: 100%;
}

.current-reviews .total-reviews {
  padding: 12px 16px 6px;
  background: #f1f6fb;
  border-radius: 6px;
}

.current-reviews .total-reviews:not(:last-child) {
  margin-bottom: 16px;
}

.current-reviews .total-reviews .label {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.current-reviews .total-reviews .label img {
  display: block;
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.current-reviews .total-reviews .label span {
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #737373;
}

.current-reviews .total-reviews p {
  font-size: 24px;
  line-height: 36px;
  color: #37474f;
  font-weight: 600;
}

.current-reviews .review-category {
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  padding: 23px 16px 17px;
  height: 100%;
}

.current-reviews .review-category span {
  text-align: center;
  display: block;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
  font-weight: 500;
}

.current-reviews .review-category .chart-wrapper {
  height: 90px;
  width: 90px;
  margin: 0 auto;
  position: relative;
  margin-bottom: 16px;
}

.current-reviews .review-category .chart-wrapper img {
  display: block;
  max-width: 100%;
}

.current-reviews .review-category .chart-wrapper strong {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #37474f;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.legends {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -18px;
  position: relative;
  top: 14px;
}

.legends .legend {
  display: flex;
  align-items: center;
}

.legends .legend:not(:last-child) {
  margin-right: 30px;
}

.legends .legend .oval {
  height: 14px;
  width: 14px;
  border-radius: 50%;
  margin-right: 8px;
}

.legends .legend span {
  font-size: 12px;
  line-height: 18px;
  color: #37474f;
}

.legends .legend.positive .oval {
  background: #5ecda5;
}

.legends .legend.negative .oval {
  background: #ef818f;
}

/* items-list

--------------------------------------------------------------------------------------------------------*/

.items-list .list .item {
  padding: 12px;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  display: flex;
}

.items-list .list .item:not(:last-child) {
  margin-bottom: 12px;
}

.items-list .list .item .details.with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.items-list .list .item .details .oval {
  height: 34px;
  width: 34px;
  background-color: #008cff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}

.items-list .list .item .details .oval img {
  display: block;
  height: 24px;
  width: 24px;
}

.items-list .list .item .details strong {
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: #37474f;
  padding-bottom: 2px;
  display: block;
}

.items-list .list .item .details strong + span {
  font-size: 14px;
  line-height: 20px;
  color: #737373;
}

.items-list .list .item small {
  font-size: 12px;
  line-height: 18px;
  color: #737373;
  display: inline-grid;
  margin-left: auto;
}

/* new styles
--------------------------------------------------------------------------------------------------------*/

/* tabs wrapper
--------------------------------------------------------------------------------------------------------*/
.card.tabs-wrapper {
  padding-bottom: 0;
}

.tabs-wrapper {
  padding-top: 0;
}

.tabs-wrapper .primary-tabs {
  margin: 0;
}

.tabs-wrapper .primary-tabs .MuiTab-root {
  text-transform: none;
}

.tabs-wrapper .MuiTabPanel-root {
  padding: 0;
  margin-top: 24px !important;
  padding-bottom: 1px;
}

.tabs-wrapper .tabs-header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  flex-wrap: wrap;
  gap: 12px;
}

.tabs-wrapper .tabs-header h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #212121;
}

.tabs-wrapper .tabs-header .right {
  margin-left: auto;
}

/* secondary toggle buttons
--------------------------------------------------------------------------------------------------------*/
.MuiToggleButtonGroup-root.secondary-toggle-buttons {
  background: #f3f4f6;
  border-radius: 6px;
  padding: 4px;
  position: relative;
  flex-wrap: wrap;
}

.MuiToggleButtonGroup-root.secondary-toggle-buttons .MuiToggleButton-root {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  padding: 5px 12px;
  border: none;
  text-transform: none;
  border-radius: 6px !important;
}

.MuiToggleButtonGroup-root.secondary-toggle-buttons .MuiToggleButton-root img {
  height: 16px;
  width: 16px;
  display: block;
  margin-right: 6px;
}

.MuiToggleButtonGroup-root.secondary-toggle-buttons
  .MuiToggleButton-root.Mui-selected {
  background: #fff;
}

.MuiToggleButtonGroup-root.secondary-toggle-buttons .dateFilterInput {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
  z-index: 0;
  height: 36px;
  width: 100px;
}

/* inner card
--------------------------------------------------------------------------------------------------------*/
.inner-card {
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  margin-bottom: 24px;
  height: calc(100% - 24px);
}

.inner-card.grid {
  padding: 0;
}

.inner-card.grid .primary-grid {
  border-left: 0;
  border-right: 0;
  border-radius: 0;
}

.inner-card.grid .primary-grid .MuiDataGrid-columnHeader {
  border-top: 1px solid #d8d8d8;
}

.inner-card.grid .primary-grid .MuiDataGrid-columnHeaders {
  border-radius: 0;
}

.inner-card.grid .primary-grid .MuiOutlinedInput-notchedOutline {
  border-radius: 0;
}

.inner-card.grid .card-header {
  padding: 16px;
  margin-bottom: 0;
}

.inner-card.grid .pagination-wrapper {
  padding-left: 16px;
}

.inner-card .card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.inner-card .card-header .title {
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #212121;
}

.inner-card .card-header .title span {
  font-size: 10px;
}

.inner-card .card-header .title span.green {
  color: #00a28c;
}

.inner-card .card-header .title.semibold {
  font-weight: 600;
}

/* guest marketing
--------------------------------------------------------------------------------------------------------*/
.guest-marketing .stats::after {
  display: none;
}

.guest-marketing .stats .card-header {
  margin-bottom: 14px;
}

.guest-marketing .stats .value {
  display: flex;
  align-items: center;
  gap: 14px;
}

.guest-marketing .stats .icon {
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e3f2fe;
  border-radius: 12px;
}

.guest-marketing .stats .icon img {
  display: block;
  height: 24px;
  width: 24px;
}

.guest-marketing .stats strong {
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  color: #212121;
}

.guest-marketing .stats ul {
  width: 100%;
}

.guest-marketing .stats ul li:not(:last-child) {
  border-bottom: 1px solid #e5e7eb;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.guest-marketing .list ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.guest-marketing .list ul li:not(:last-child) {
  margin-bottom: 5px;
}

.guest-marketing .list strong {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #212121;
}

.guest-marketing .list span {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #9ca3af;
}

/* guest locations
--------------------------------------------------------------------------------------------------------*/
.guest-locations .card-header .title,
.guest-locations .card-header .MuiToggleButtonGroup-root {
  flex-shrink: 0;
}

.guest-locations .card-header ul {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
}

.guest-locations .card-header ul li {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  padding-left: 22px;
  position: relative;
}

.guest-locations .card-header ul li::before {
  content: '';
  height: 14px;
  width: 14px;
  display: block;
  border-radius: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.guest-locations .card-header ul li.selected::before {
  border: 1px solid #808080;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
}

.guest-locations .card-header ul li.blue::before {
  background: #008cff;
}

.guest-locations .card-header ul li.orange::before {
  background: #ff9980;
}

.guest-locations .card-header ul li.pink::before {
  background: #e7b3ff;
}

.guest-locations .card-header ul li.sky-blue::before {
  background: #9ee8f0;
}

.guest-locations .card-header .MuiToggleButtonGroup-root {
  margin-left: 0;
}

.guest-locations img {
  display: block;
  width: 100%;
  margin: 0 auto;
}

/* search input
--------------------------------------------------------------------------------------------------------*/
.form-group.search-input .MuiFormControl-root .MuiInputAdornment-root {
  border: 0;
}

.form-group.search-input
  .MuiFormControl-root
  .MuiOutlinedInput-notchedOutline
  legend
  span {
  padding: 0;
}

/* chip
--------------------------------------------------------------------------------------------------------*/
.MuiChip-root.MuiChip-sizeSmall.primary-chip {
  height: 28px;
  padding-left: 10px;
  padding-right: 10px;
}

.MuiChip-root.MuiChip-sizeSmall.primary-chip .MuiChip-label {
  font-weight: 400;
  font-size: 10px;
  line-height: 26px;
  padding: 0;
}

.MuiChip-root.primary-chip.MuiChip-filledSuccess {
  background: rgba(28, 204, 54, 0.1);
}

.MuiChip-root.primary-chip.MuiChip-filledSuccess .MuiChip-label {
  color: #189e3c;
}

.MuiChip-root.primary-chip.MuiChip-filledInfo {
  background: rgba(0, 140, 255, 0.1);
}

.MuiChip-root.primary-chip.MuiChip-filledInfo .MuiChip-label {
  color: #008cff;
}

/* campaign list
--------------------------------------------------------------------------------------------------------*/
.campaign-list .inner-card {
  padding-left: 0;
  padding-right: 0;
}

.campaign-list .campaign-header {
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
}

.campaign-list .campaign-header::after {
  content: '';
  height: 1px;
  left: 16px;
  right: 16px;
  bottom: 0;
  position: absolute;
  display: block;
  background: #e5e7eb;
}

.campaign-list .campaign-header .left {
  display: flex;
  gap: 15px;
  align-items: center;
}

.campaign-list .campaign-header .MuiChip-root {
  padding-left: 20px;
  position: relative;
}

.campaign-list .campaign-header .MuiChip-root::after {
  content: '';
  height: 5px;
  width: 5px;
  border-radius: 50px;
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.campaign-list .campaign-header .MuiChip-root.MuiChip-filledSuccess::after {
  background-color: #189e3c;
}

.campaign-list .campaign-header .MuiChip-root.MuiChip-filledInfo::after {
  background-color: #008cff;
}

.campaign-list .campaign-header .right {
  display: flex;
  gap: 15px;
  align-items: center;
}

.campaign-list .campaign-header .right .form-group {
  max-width: 132px;
}

.campaign-list .campaign-body {
  display: flex;
  flex-wrap: wrap;
}

.campaign-list .campaign-body .campaign-details {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
  padding-left: 16px;
  padding-right: 16px;
  border-right: 1px solid #e5e7eb;
}

.campaign-list .campaign-body .campaign-details > img {
  display: block;
  height: 154px;
  width: 154px;
  border-radius: 10px;
}

.campaign-list .campaign-body .campaign-details .campaign-type,
.campaign-list .campaign-body .campaign-details .campaign-date {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.campaign-list .campaign-body .campaign-details .campaign-type .MuiChip-root {
  margin-right: 16px;
}

.campaign-list .campaign-body .campaign-details small {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #9ca3af;
  padding-right: 8px;
  display: block;
}

.campaign-list .campaign-body .campaign-details span {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #212121;
  display: block;
}

.campaign-list .campaign-body .campaign-details p {
  padding: 5px 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #212121;
}

.campaign-list .campaign-body .campaign-details .inner > span {
  line-height: 22px;
  padding-bottom: 5px;
}

.campaign-list .campaign-body .campaign-details a {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 10px;
  color: #008cff;
}

.campaign-list .campaign-body .campaign-details a img {
  display: block;
  height: 18px;
  width: 18px;
}

.campaign-list .campaign-body .campaign-details a span {
  font-weight: 500;
  color: #008cff;
}

.campaign-list .campaign-body .campaign-details a:hover {
  text-decoration: underline;
}

.campaign-list .campaign-body ul {
  max-width: 288px;
  flex-basis: 288px;
  flex-shrink: 0;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.campaign-list .campaign-body ul:not(:last-child) {
  border-right: 1px solid #e5e7eb;
}

.campaign-list .campaign-body ul li {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
  padding: 5px 0;
}

.campaign-list .campaign-body ul li span,
.campaign-list .campaign-body ul li small {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  display: block;
}

.campaign-list .campaign-body ul li span {
  color: #9ca3af;
}

.campaign-list .campaign-body ul li small {
  color: #008cff;
  text-align: right;
}

/* create campaign dialog
--------------------------------------------------------------------------------------------------------*/
.MuiModal-root.create-campaign-dialog .MuiDialog-paper {
  max-width: 1100px;
}

.MuiModal-root.create-campaign-dialog .MuiDialog-paper .MuiDialogContent-root {
  padding: 0 50px 40px !important;
  position: relative;
  margin-top: -30px;
  z-index: 1;
}

.MuiModal-root.create-campaign-dialog
  .MuiDialog-paper
  .MuiDialogContent-root
  .stepper-wrapper
  .MuiStepper-root {
  max-width: 800px;
  margin: 0 auto 40px;
}

.MuiModal-root.create-campaign-dialog .MuiDialogTitle-root {
  padding: 10px;
}

.create-campaign-dialog .campaign-information {
  max-width: 548px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-campaign-dialog .campaign-information .nav-btn {
  max-width: 549px;
  width: 100%;
}

.create-campaign-dialog .campaign-content,
.create-campaign-dialog .campaign-location {
  padding: 0 20px 20px;
}

.create-campaign-dialog .campaign-content .inner,
.create-campaign-dialog .campaign-location .inner {
  display: flex;
  gap: 50px;
  padding-top: 20px;
  margin-right: -20px;
}

.create-campaign-dialog .campaign-content .inner .right {
  max-width: 381px;
  flex-basis: 381px;
  background: #f2f5f9;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
}

.create-campaign-dialog .campaign-content .inner .right img,
.create-campaign-dialog .campaign-location .inner > img {
  display: block;
  max-width: 100%;
}

.create-campaign-dialog .campaign-content .inner .left,
.create-campaign-dialog .campaign-location .inner .left {
  flex-grow: 1;
}

.create-campaign-dialog .campaign-content .inner .left .nav-btn,
.create-campaign-dialog .campaign-location .inner .left .nav-btn {
  width: 100%;
  margin-top: 50px;
}

.create-campaign-dialog .campaign-content > .MuiButton-root.nav-btn,
.create-campaign-dialog .campaign-location > .MuiButton-root.nav-btn {
  max-width: 463px;
  width: 100%;
  margin: 0 auto;
}

.create-campaign-dialog .campaign-review {
  margin-bottom: -10px;
}

.create-campaign-dialog .campaign-review > .title,
.create-campaign-dialog .campaign-review > .subtext {
  text-align: center;
}

.create-campaign-dialog .campaign-review ul {
  max-width: 900px;
  margin: 0 auto;
  padding: 30px;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  margin-bottom: 26px;
}

.create-campaign-dialog .campaign-review ul li {
  display: flex;
  align-items: flex-start;
  gap: 16px;
}

.create-campaign-dialog .campaign-review ul li:not(:last-child) {
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e8ef;
  margin-bottom: 15px;
}

.create-campaign-dialog .campaign-review ul li .left {
  flex-grow: 1;
}

.create-campaign-dialog .campaign-review ul li .list-title {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 11px;
}

.create-campaign-dialog .campaign-review ul li .list-title h6 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #212121;
}

.create-campaign-dialog .campaign-review ul li .list-title img {
  display: block;
  height: 24px;
  width: 24px;
}

.create-campaign-dialog .campaign-review ul li p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #000000;
  opacity: 0.7;
  padding-bottom: 5px;
}

.create-campaign-dialog .campaign-review ul li p:last-child {
  padding-bottom: 0;
}

.create-campaign-dialog .campaign-review .MuiButton-root.nav-btn {
  max-width: 463px;
  width: 100%;
  margin: 0 auto;
}

/* stepper-wrapper
--------------------------------------------------------------------------------------------------------*/
.stepper-wrapper .MuiStepper-root .MuiStepConnector-root {
  left: calc(-50% + 16px);
  right: calc(50% + 16px);
}

.stepper-wrapper .MuiStepper-root .MuiStepLabel-root .MuiSvgIcon-root {
  height: 32px;
  width: 32px;
  color: rgba(0, 140, 255, 0.1);
}

.stepper-wrapper
  .MuiStepper-root
  .MuiStepLabel-root
  .MuiSvgIcon-root.Mui-active,
.stepper-wrapper
  .MuiStepper-root
  .MuiStep-root.visited
  .MuiStepLabel-root
  .MuiStepLabel-label,
.stepper-wrapper .MuiStepper-root .MuiStepLabel-label.Mui-active,
.stepper-wrapper
  .MuiStepper-root
  .MuiStep-root.visited
  .MuiStepLabel-root
  .MuiSvgIcon-root {
  color: #008cff;
  cursor: pointer;
}

.stepper-wrapper
  .MuiStepper-root
  .MuiStepLabel-root
  .MuiSvgIcon-root.Mui-active
  .MuiStepIcon-text,
.stepper-wrapper
  .MuiStepper-root
  .MuiStep-root.visited
  .MuiStepLabel-root
  .MuiSvgIcon-root
  .MuiStepIcon-text {
  fill: #fff;
}

.stepper-wrapper
  .MuiStepper-root
  .MuiStepLabel-root
  .MuiSvgIcon-root
  .MuiStepIcon-text {
  font-weight: 600;
  font-size: 12px;
  line-height: 26px;
  fill: #008cff;
}

.stepper-wrapper .MuiStepper-root .MuiStepLabel-label {
  margin-top: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #6b7280;
}

.stepper-wrapper .MuiStepper-root .MuiStep-root {
  padding-left: 0;
  padding-right: 0;
  min-width: 0;
}

.stepper-wrapper .MuiStepper-root .MuiStep-root .MuiStepConnector-root {
  top: 16px;
}

.stepper-wrapper
  .MuiStepper-root
  .MuiStep-root
  .MuiStepConnector-root
  .MuiStepConnector-line {
  border-color: rgba(0, 140, 255, 0.1);
}

.stepper-wrapper
  .MuiStepper-root
  .MuiStep-root.visited
  + .MuiStep-root
  .MuiStepConnector-root
  .MuiStepConnector-line {
  border-color: #008cff;
}

.stepper-wrapper .stepper-body .title {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
  margin-bottom: 14px;
}

.stepper-wrapper .stepper-body .subtext {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #737373;
  margin-bottom: 30px;
}

/* content builder
--------------------------------------------------------------------------------------------------------*/
.content-builder {
  display: flex;
  gap: 50px;
  margin: -10px -20px 0;
}

.content-builder .left {
  max-width: 388px;
  flex-basis: 388px;
  flex-shrink: 0;
}

.content-builder .MuiTab-root {
  padding-top: 0;
}

.content-builder .MuiAccordion-root {
  box-shadow: none;
  padding-top: 5px;
  margin: 0;
}

.content-builder .MuiAccordion-root.Mui-expanded,
.content-builder
  .MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content,
.content-builder .MuiTabs-root {
  margin: 0;
}

.content-builder .MuiAccordion-root.Mui-expanded:first-of-type,
.content-builder .MuiAccordion-root.Mui-expanded {
  margin-top: 0;
}

.content-builder .MuiAccordion-root.Mui-expanded::before {
  opacity: 1;
}

.content-builder .MuiAccordion-root::before {
  background-color: #e6e8ef;
}

.content-builder .MuiAccordion-root .MuiAccordionSummary-root {
  padding: 20px 0;
  min-height: auto;
}

.content-builder
  .MuiAccordion-root
  .MuiAccordionSummary-root
  .MuiAccordionSummary-content {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
}

.content-builder .MuiAccordion-root .MuiAccordionDetails-root {
  padding: 0 0 10px;
}

.content-builder .MuiAccordion-root:not(:last-child) .MuiCollapse-root {
  border-bottom: 1px solid #e6e8ef;
}

.content-builder .builder-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px;
  border: 1px solid #e5e7eb;
  border-radius: 16px;
  margin: 0 0 15px;
  height: calc(100% - 15px);
  cursor: pointer;
}

.content-builder .builder-block .drag-icon {
  display: block;
  height: 16px;
  width: 16px;
  margin-bottom: 11px;
}

.content-builder .builder-block .block-icon {
  display: block;
  height: 30px;
  width: 30px;
  margin-bottom: 11px;
}

.content-builder .builder-block p {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #212121;
}

.content-builder .MuiTabPanel-root {
  padding: 5px 0 0;
}

.content-builder .right {
  flex-grow: 1;
  border-radius: 20px;
  background: #f2f5f9;
  margin-bottom: 25px;
}

/* builder preview
--------------------------------------------------------------------------------------------------------*/
.builder-preview .section,
.builder-preview .section .content {
  position: relative;
}

.builder-preview .section.selected {
  background: #008cff;
}

.builder-preview .section .title {
  background: #008cff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  height: 28px;
  border-radius: 5px 5px 0 0;
  left: 0;
  top: -28px;
  padding: 0 10px;
}

.builder-preview .section .block-action {
  border: 1px solid #e5e7eb;
  padding: 5px 3px;
  background: #fff;
  position: absolute;
  left: -28px;
  top: -28px;
}

.builder-preview .section button {
  background: none;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.3s ease;
  padding: 0;
}

.builder-preview .section button:hover {
  background: #f3f4f6;
}

.builder-preview .section .block-action button {
  padding: 5px;
}

.builder-preview .section .block-action button img {
  display: block;
  height: 14px;
  width: 14px;
}

.builder-preview .section .title span {
  display: block;
  font-size: 10px;
  line-height: 26px;
  color: #ffffff;
}

.builder-preview .section .drag-content {
  padding: 10px 16px;
  display: flex;
  gap: 8px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #d1d5db;
}

.builder-preview .section .drag-content span {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  text-align: center;
  color: #000000;
  opacity: 0.5;
  display: block;
  flex-grow: 1;
}

.builder-preview .section .drag-content button {
  padding: 3px;
}

.builder-preview .section .drag-content button img {
  display: block;
  height: 18px;
  width: 18px;
}

.builder-preview .section .social-links {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.builder-preview .section .social-links button img {
  display: block;
  height: 24px;
  width: 24px;
}

/* upgrade guest marketing dialog
--------------------------------------------------------------------------------------------------------*/
.MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialog-paper {
  max-width: 1004px;
}

.MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root {
  padding: 0 50px 50px !important;
  display: flex;
}

.MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .left {
  max-width: calc(50% - 28px);
  flex-basis: calc(50% - 28px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
  padding-right: 50px;
  border-right: 1px solid #e5e7eb;
}

.MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .right {
  max-width: calc(50% + 28px);
  flex-basis: calc(50% + 28px);
  padding-left: 50px;
}

.MuiDialog-root.upgrade-guest-marketing-dialog .highlighted-text {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 10px;
  border-radius: 2px;
  background: rgba(0, 140, 255, 0.1);
}

.MuiDialog-root.upgrade-guest-marketing-dialog .highlighted-text span {
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #008cff;
}

.MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .left h6 {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #212121;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .left
  ul
  li {
  display: flex;
  align-items: center;
  gap: 15px;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .left
  ul
  li
  img {
  display: block;
  height: 24px;
  width: 24px;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .left
  ul
  li
  span {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #212121;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .left
  ul
  li:not(:last-child) {
  margin-bottom: 30px;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .right
  .price {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .right
  h6 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #212121;
}

.MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .right p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #737373;
  margin-bottom: 30px;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .right
  .MuiButton-root {
  width: 100%;
}

.MuiDialog-root.upgrade-guest-marketing-dialog
  .MuiDialogContent-root
  .right
  .form-group {
  margin-bottom: 30px;
}

/* lead generator form dialog
--------------------------------------------------------------------------------------------------------*/
.lead-generator-form-dialog.MuiModal-root .MuiDialogContent-root {
  display: flex;
  justify-content: center;
  padding-top: 20px !important;
  margin-top: -50px;
}

.lead-generator-form-dialog .form {
  max-width: 656px;
  width: 100%;
  margin: auto;
}

.lead-generator-form-dialog .form h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #212121;
  padding-bottom: 14px;
}

.lead-generator-form-dialog .form p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  color: #737373;
  padding-bottom: 30px;
}

.lead-generator-form-dialog .form .form-group {
  width: 100%;
}

.lead-generator-form-dialog .form .privacy-policy-link {
  display: flex;
  align-items: center;
  margin-top: -9px;
  margin-bottom: 11px;
}

.lead-generator-form-dialog
  .form
  .privacy-policy-link
  .MuiFormControlLabel-root {
  margin-right: 4px;
}

.lead-generator-form-dialog .form .privacy-policy-link a {
  font-size: 14px;
  line-height: 21px;
  text-decoration: underline !important;
}

.lead-generator-form-dialog .form .MuiButton-root {
  width: 100%;
}

/* campaign location
--------------------------------------------------------------------------------------------------------*/
.campaign-location .primary-checkbox {
  margin-top: -9px;
  margin-bottom: -9px;
}

.campaign-location .MuiButton-root {
  width: 100%;
}

/* media queries
--------------------------------------------------------------------------------------------------------*/
@media (max-width: 1439px) {
  .current-reviews .card-body {
    display: block;
  }

  .current-reviews .left {
    max-width: 100%;
    display: flex;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .current-reviews .right {
    max-width: 100%;
  }

  .current-reviews .total-reviews {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
  }

  .current-reviews .total-reviews:not(:last-child) {
    margin-bottom: 0;
    margin-right: 16px;
  }

  /* new
  --------------------------------------------------------------------------------------------------------*/
  .campaign-list .campaign-body .campaign-details {
    flex-basis: 100%;
    margin-bottom: 16px;
    border-right: 0;
    padding-bottom: 16px;
    position: relative;
  }

  .campaign-list .campaign-body .campaign-details::after {
    content: '';
    display: block;
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 0;
    height: 1px;
    background: #e5e7eb;
  }

  .campaign-list .campaign-body ul {
    flex: 1;
    max-width: 100%;
  }
}

@media (max-width: 1199px) {
  .sidebar {
    left: -246px;
    z-index: 20;
    box-shadow: none;
  }

  .wrapper {
    padding: 0;
  }

  .sidebar-toggle .sidebar {
    left: 0;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }

  .sidebar-toggle .overlay button {
    visibility: visible;
    opacity: 1;
  }

  .sidebar .logo img.mobile-logo {
    display: none;
  }

  body.sidebar-toggle {
    overflow: hidden;
  }

  .file-upload-wrapper {
    padding: 0;
  }

  .menu-wrapper.card {
    padding-right: 20px;
    padding-left: 20px;
  }

  .draggable-list li.draggable-item {
    min-height: auto;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .draggable-list li.draggable-item {
    margin-bottom: 20px;
    padding-right: 14px;
  }

  .draggable-list .item-label {
    font-size: 18px;
    line-height: 22px;
  }

  .header .mobile-logo {
    display: block;
    margin-right: 16px;
  }

  .page-title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
  }

  .page-top {
    padding-top: 14px;
    min-height: 70px;
  }

  .file-upload-wrapper .file-upload {
    padding: 20px;
    min-height: 180px;
  }

  .file-preview {
    height: 180px;
  }

  .barcode-dialog .MuiDialogContent-root .barcode-template {
    margin: 0 20px 10px;
    max-width: calc(50% - 10px);
    flex-basis: calc(50% - 10px);
  }

  .barcode-dialog .MuiDialogContent-root .barcode-template:first-child {
    margin-left: 0;
  }

  .barcode-dialog .MuiDialogContent-root .barcode-template:last-child {
    margin-right: 0;
  }

  .content-builder .left {
    max-width: 360px;
    flex-basis: 360px;
  }

  .content-builder {
    gap: 40px;
  }
}

@media (max-width: 991px) {
  .total-earning {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    align-items: center;
  }

  .total-earning span {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    padding-bottom: 8px;
  }

  .total-earning p {
    font-size: 32px;
    line-height: 40px;
    text-align: left;
  }

  .total-earning .oval {
    margin: 0;
    margin-right: 20px;
  }

  .total-earning .oval,
  .total-earning .oval-bg {
    height: 80px;
    width: 80px;
  }

  .total-earning .oval .fade {
    height: 78px;
    width: 78px;
  }

  .total-earning .oval img {
    height: 44px;
    width: 44px;
  }

  .current-reviews .review-category {
    margin-bottom: 16px;
    height: calc(100% - 16px);
  }

  .legends {
    top: 0;
  }

  .current-reviews .card-body {
    margin-bottom: 16px;
  }
  .barcode-template.two .outer {
    display: block;
    min-height: auto;
  }

  .header .form-group {
    max-width: 260px;
  }

  .menu-content {
    display: block;
  }

  .menu-content > .left {
    max-width: 100%;
    flex-basis: 100%;
    margin: 0 0 50px;
  }

  .menu-content > .right {
    margin: 0 auto;
  }

  .menu-items-wrapper > .right,
  .menu-items-wrapper > .left {
    max-width: 50%;
    flex-basis: 50%;
  }

  .login-box {
    max-width: 300px;
    margin: 0 auto;
  }

  .login-box > p {
    font-size: 14px;
    line-height: 20px;
  }

  .header .btn-icon.MuiButton-root img {
    height: 32px;
  }

  .header .btn-icon.MuiButton-root {
    width: 54px;
  }

  .file-upload-wrapper .file-upload {
    min-height: 240px;
  }

  .file-preview {
    height: 240px;
  }

  .barcode-dialog .MuiDialogContent-root {
    flex-direction: column;
    align-items: center;
  }

  .barcode-dialog .MuiDialogContent-root .barcode-template {
    margin-left: 0;
    margin-right: 0;
    min-width: 430px;
  }

  .barcode-dialog .MuiDialogContent-root .barcode-template:first-child {
    margin-bottom: 30px;
  }

  .file-upload-wrapper {
    padding: 20px 0 0;
    margin-bottom: 20px;
  }

  .header .form-group {
    display: none;
  }

  .sidebar .form-group {
    display: block;
  }

  .sidebar {
    width: 280px;
    left: -280px;
  }

  .sidebar .logo img {
    max-height: 32px;
  }

  .error-message h1 {
    font-size: 128px;
    line-height: 128px;
  }

  .error-message h2 {
    font-size: 32px;
    line-height: 40px;
  }

  .error-message p {
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 40px;
  }

  .tab-detail-summary {
    flex-wrap: wrap;
  }

  .tab-detail-summary-right {
    width: 100%;
    justify-content: flex-end;
    margin-top: 20px;
  }

  /* new
  --------------------------------------------------------------------------------------------------------*/
  .tabs-wrapper .tabs-header.analytics {
    flex-direction: column;
    align-items: flex-start;
  }

  .tabs-wrapper .tabs-header .MuiToggleButtonGroup-root {
    margin-left: auto;
  }

  .guest-locations .card-header ul {
    flex-basis: 100%;
    order: 3;
  }

  .guest-locations .card-header .MuiToggleButtonGroup-root {
    order: 2;
  }

  .MuiModal-root.create-campaign-dialog .MuiDialog-paper .MuiDialogContent-root,
  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root {
    padding: 0 24px 24px !important;
  }

  .create-campaign-dialog .campaign-review {
    margin-bottom: 0;
  }

  .create-campaign-dialog .campaign-content .inner,
  .create-campaign-dialog .campaign-location .inner {
    gap: 24px;
    padding-top: 0;
    margin-right: 0;
  }

  .create-campaign-dialog .campaign-content .inner .right,
  .create-campaign-dialog .campaign-location .inner img {
    max-width: 340px;
    flex-basis: 340px;
  }

  .create-campaign-dialog .campaign-location .inner img {
    display: block;
    border-radius: 20px;
    object-fit: cover;
  }

  .content-builder .left {
    max-width: 290px;
    flex-basis: 290px;
  }

  .create-campaign-dialog .campaign-content,
  .create-campaign-dialog .campaign-location {
    padding: 0;
  }

  .content-builder {
    gap: 24px;
    margin-left: 0;
    margin-right: 0;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .left {
    padding-right: 24px;
    gap: 24px;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .right {
    padding-left: 24px;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog
    .MuiDialogContent-root
    .left
    ul
    li:not(:last-child) {
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .page-top {
    display: block;
  }
  .dashboard-header-main {
    margin-top: 10px;
  }
  .login-wrapper .right {
    max-width: 60%;
    flex-basis: 60%;
  }

  .login-wrapper .left {
    max-width: 40%;
    flex-basis: 40%;
  }

  .login-wrapper .left h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .login-wrapper .left p {
    font-size: 20px;
    line-height: 26px;
  }

  .user-menu .MuiButton-root {
    min-width: unset;
    padding: 0;
  }

  .user-menu .MuiButton-root .user-icon {
    margin: 0;
  }

  .notification-menu {
    margin-right: 8px;
    margin-left: 0;
  }

  .header .btn-icon.MuiButton-root {
    width: 52px;
    min-height: 70px;
  }

  .header .btn-icon.MuiButton-root img {
    height: 32px;
    width: 32px;
  }

  .user-menu .MuiButton-root .user-info,
  .user-menu .MuiButton-root > img {
    display: none;
  }

  .main-content {
    min-height: calc(100vh - 118px);
  }

  .primary-menu.user-menu-popup .MuiMenu-paper span,
  .primary-menu.user-menu-popup .MuiMenu-paper .MuiDivider-root {
    display: block;
  }

  .file-upload-wrapper {
    padding: 26px 0 0;
  }

  .menu-items-wrapper:not(.empty) {
    display: block;
    padding: 0;
  }

  .menu-items-wrapper > .right,
  .menu-items-wrapper > .left {
    max-width: 100%;
    flex-basis: 100%;
  }

  .menu-items-wrapper > .left {
    border-radius: 6px 6px 0 0;
  }

  .menu-items-wrapper > .right {
    border-radius: 0 0 6px 6px;
  }

  .switch-tabs .MuiTab-root {
    min-width: 160px;
  }

  .pagination-wrapper {
    flex-direction: column;
    padding: 16px 0;
  }

  .pagination-wrapper .total-pages {
    margin-bottom: 10px;
  }

  .primary-dialog.details-dialog .MuiDialogContent-root {
    padding: 15px 20px 0 !important;
  }

  .primary-dialog.details-dialog .MuiDialogTitle-root {
    padding: 14px 10px 6px 20px;
  }

  .primary-dialog .MuiDialog-paper {
    margin: 16px;
    width: calc(100% - 32px);
    max-height: calc(100% - 32px);
  }

  .table-list .MuiGrid-container .MuiGrid-item {
    padding-left: 10px;
  }

  .table-list {
    padding: 10px 10px 0 20px;
  }

  .btn-list .right {
    margin-left: 0;
  }

  .tab-detail-summary {
    display: block;
  }

  .tab-detail-summary ul {
    margin-top: 20px;
  }

  .tab-detail-summary ul li {
    width: 100%;
  }

  .tab-detail-summary ul li:first-child {
    border-left: 0;
    margin-left: 0;
    padding-left: 0;
  }

  /* new
  --------------------------------------------------------------------------------------------------------*/
  .tabs-wrapper .MuiTabPanel-root {
    margin-top: 16px !important;
  }

  .tabs-wrapper .tabs-header {
    margin-bottom: 16px;
  }

  .inner-card {
    margin-bottom: 16px;
    height: calc(100% - 16px);
  }

  .inner-card.grid .card-header .form-group.search-input {
    flex-basis: 100%;
  }

  .create-campaign-dialog .campaign-content .inner,
  .create-campaign-dialog .campaign-location .inner {
    flex-direction: column-reverse;
  }

  .create-campaign-dialog .campaign-content .inner .right {
    max-width: 100%;
    flex-basis: 100%;
    min-height: 240px;
  }

  .create-campaign-dialog .campaign-location .inner img {
    max-width: 100%;
    max-height: 240px;
  }

  .create-campaign-dialog .campaign-content,
  .create-campaign-dialog .campaign-location {
    padding-bottom: 0;
  }

  .create-campaign-dialog .campaign-content .inner .left .nav-btn,
  .create-campaign-dialog .campaign-location .inner .left .nav-btn {
    margin-top: 20px;
  }

  .stepper-wrapper .stepper-body .title,
  .stepper-wrapper .stepper-body .subtext {
    text-align: center;
  }

  .content-builder {
    flex-direction: column;
  }

  .content-builder .left {
    max-width: 100%;
    flex-basis: 100%;
  }

  .content-builder .MuiTabPanel-root,
  .content-builder .MuiAccordion-root {
    padding-top: 0;
  }

  .content-builder {
    gap: 8px;
  }

  .content-builder {
    margin-top: 0;
  }

  .create-campaign-dialog .campaign-review ul {
    padding: 24px;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root {
    flex-direction: column;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .left,
  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .right {
    max-width: 100%;
    flex-basis: 100%;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .left {
    padding-right: 0;
    border-right: 0;
    border-bottom: 1px solid #e5e7eb;
    padding-bottom: 24px;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .right {
    padding-left: 0;
    padding-top: 24px;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog
    .MuiDialogContent-root
    .right
    .form-group {
    margin-bottom: 20px;
  }

  .MuiModal-root.create-campaign-dialog
    .MuiDialog-paper
    .MuiDialogContent-root
    .stepper-wrapper
    .MuiStepper-root {
    margin-bottom: 24px;
  }
}

@media (max-width: 575px) {
  .impersonate-button {
    margin-left: 0px !important;
    margin-top: 10px;
  }
  .dashboard-input-main {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  }
  .dashboard-header-main {
    display: block;
  }
  .compare-to-text {
    font-size: 14px;
  }
  .current-reviews .total-reviews .label {
    display: block;
  }

  .current-reviews .total-reviews .label img {
    margin-bottom: 6px;
  }

  .current-reviews .total-reviews {
    padding-left: 12px;

    padding-right: 12px;
  }

  .items-list .card-header {
    display: block;

    margin-bottom: 10px;
  }

  .items-list .card-header h5 {
    padding-bottom: 10px;
  }
  .login-wrapper {
    flex-direction: column;
  }

  .barcode-template.one .card {
    width: 100%;
  }

  .barcode-template.two .table-number {
    position: relative;
    bottom: -6px;
  }

  .barcode-template.two .card {
    width: 100%;
  }

  .login-wrapper .left {
    max-width: 100%;
    min-height: 150px;
    flex-grow: 1;
    flex-basis: 100%;
  }

  .primary-menu .MuiMenu-paper .MuiMenuItem-root {
    min-height: unset;
  }

  .login-wrapper .right {
    max-width: 100%;
    flex-basis: unset;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 40px 20px;
  }

  .login-box {
    flex-shrink: 0;
  }

  .header .btn-icon.MuiButton-root {
    width: 46px;
  }

  .user-menu .MuiButton-root .user-icon {
    height: 38px;
    width: 38px;
  }

  .header .toggle-menu.MuiButton-root {
    height: 40px;
    width: 40px;
  }

  .header .toggle-menu.MuiButton-root img {
    height: 26px;
    width: 26px;
  }

  .header .form-group {
    max-width: 200px;
  }

  .btn-list .MuiButton-root {
    min-width: 120px;
  }

  .btn-list .MuiButton-root:not(:last-child) {
    margin-right: 10px;
  }

  .page-top .right .MuiButton-root.MuiButton-containedPrimary,
  .page-top .right .MuiButton-root.MuiButton-outlined {
    min-width: 40px;
    height: 38px;
    padding: 0;
    font-size: 0;
  }

  .page-top .right .MuiButton-root.MuiButton-containedPrimary img,
  .page-top .right .MuiButton-root.MuiButton-outlined img {
    margin: 0;
  }

  .page-top .page-title {
    font-size: 20px;
    line-height: 27px;
  }

  .draggable-list .action-menu-btn {
    display: flex;
  }

  .draggable-list:not(.category-list, .category-item-list)
    .MuiButton-root:not(.action-menu-btn) {
    display: none;
  }

  .draggable-list .drag-handler {
    margin-right: 10px;
  }

  .draggable-list .item-label {
    font-size: 16px;
  }

  .draggable-list li.draggable-item {
    padding-left: 8px;
  }

  .draggable-list .right .primary-switch {
    margin-right: 0;
  }

  .switch-tabs .MuiTab-root {
    min-width: 136px;
    height: 40px;
    padding: 8px 10px;
    min-height: 40px;
  }

  .switch-tabs.MuiTabs-root {
    min-height: 40px;
  }

  .file-upload.vertical {
    flex-direction: column;
  }

  .file-upload.vertical img {
    margin: 0 0 14px 0;
  }

  .menu-items-wrapper > .left,
  .menu-items-wrapper > .right {
    padding-left: 15px;
    padding-right: 15px;
  }

  .detail-info {
    height: calc(100% - 20px);
    margin-bottom: 20px;
  }

  .file-upload-wrapper .file-upload {
    min-height: 180px;
  }

  .file-preview {
    height: 180px;
  }

  /* .barcode-dialog .MuiDialogContent-root .barcode-template {
    min-width: auto;
    max-width: 260px;
    width: 100%;
  } */
  /* .barcode-dialog .MuiDialogContent-root .barcode-template .card {
    flex-direction: column;
    align-items: center;
  } */
  .primary-dialog.table-list-dialog .MuiDialogContent-root,
  .primary-dialog.table-list-dialog .MuiDialogActions-root {
    padding-left: 15px;
    padding-right: 15px;
  }

  .primary-dialog.table-list-dialog .MuiDialogTitle-root {
    padding-left: 15px;
    padding-right: 5px;
  }

  .menu-content > .right .mobile-menu-preview {
    max-width: 335px;
    margin: 0 auto;
    height: 678px;
  }

  .pos-config ul li {
    display: block;
  }

  .pos-config ul li .form-group {
    margin-bottom: 10px;
    margin-right: 0;
  }

  .pos-config ul li .icon-btn {
    margin-left: auto;
  }

  .primary-dialog.alert-dialog .btn-list .MuiButton-root {
    min-width: auto;
    padding: 10px 6px;
    max-width: 160px;
    font-size: 14px;
    width: calc(50% - 5px);
  }

  .table-list .form-group .MuiInputAdornment-positionEnd {
    min-width: 140px;
    max-width: 140px;
  }

  /* .barcode-template .left span {
    text-align: center;
    padding: 0;
    display: block;
  }
  .barcode-template .right strong {
    padding-top: 20px;
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 6px;
  }
  .barcode-template .left .arrow {
    transform: rotate(65deg);
    top: 170px;
    right: -30px;
  }
  .barcode-template .right {
    margin: 0 auto;
    padding: 196px 10px 0;
  }
  .barcode-template .left {
    left: 50%;
    transform: translateX(-50%);
  } */
  .mobile-menu-preview .restaurant-info span,
  .mobile-menu-preview .restaurant-info strong {
    max-width: 190px;
  }

  .mobile-menu-preview .restaurant-info {
    margin-top: -68px;
  }

  .error-message h1 {
    font-size: 104px;
    line-height: 104px;
  }

  .error-message.coming-soon h1 {
    font-size: 85px;
    line-height: 104px;
  }

  .error-message h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  .error-message p {
    font-size: 16px;
    line-height: 24px;
  }

  .tab-detail-summary ul {
    display: block;
  }

  .tab-detail-summary ul li + li {
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
    border-top: 1px solid #000;
    margin-top: 10px;
    padding-top: 10px;
  }

  .tab-detail-card-header {
    display: block;
  }

  .tab-detail-card-header h6 {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .tab-detail-card-header ul {
    display: block;
  }

  .tab-detail-card-header ul li + li {
    border-left: 0;
    padding-left: 0;
    margin-left: 0;
    border-top: 1px solid #000;
    margin-top: 5px;
    padding-top: 5px;
  }

  /* new
  --------------------------------------------------------------------------------------------------------*/
  .guest-locations .card-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }

  .guest-locations .card-header .MuiToggleButtonGroup-root {
    margin-left: auto;
  }

  .campaign-list .campaign-body ul {
    flex-basis: 100%;
  }

  .campaign-list .campaign-body ul:not(:last-child) {
    margin-bottom: 12px;
    border: 0;
  }

  .campaign-list .campaign-body .campaign-details {
    margin-bottom: 12px;
    flex-direction: column;
  }

  .campaign-list .campaign-body .campaign-details > img {
    width: 100%;
    object-fit: cover;
    height: 200px;
  }

  .campaign-list .campaign-header .right {
    flex-basis: 100%;
  }

  .campaign-list .campaign-header .right .form-group {
    max-width: 100%;
    flex: 1;
  }

  .MuiModal-root.create-campaign-dialog
    .MuiDialog-paper
    .MuiDialogContent-root
    .stepper-wrapper
    .MuiStepper-root,
  .stepper-wrapper .stepper-body .subtext {
    margin-bottom: 24px;
  }

  .MuiModal-root.create-campaign-dialog .MuiDialog-paper .MuiDialogContent-root,
  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root {
    padding: 0 16px 16px !important;
  }

  .MuiModal-root.create-campaign-dialog
    .MuiDialog-paper
    .MuiDialogContent-root {
    margin-top: 0;
  }

  .content-builder .right,
  .create-campaign-dialog .campaign-review ul {
    margin-bottom: 20px;
  }

  .create-campaign-dialog .campaign-review ul {
    padding: 16px;
  }

  .create-campaign-dialog .campaign-review ul li {
    flex-direction: column;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog .MuiDialogContent-root .left {
    gap: 16px;
  }

  .MuiDialog-root.upgrade-guest-marketing-dialog
    .MuiDialogContent-root
    .left
    ul
    li:not(:last-child) {
    margin-bottom: 16px;
  }

  .stepper-wrapper .MuiStepper-root .MuiStepLabel-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media (max-width: 424px) {
  .header .form-group {
    max-width: 142px;
  }

  .menu-content > .right {
    margin: 0 -20px;
  }
}

[data-aos^='fade'][data-aos^='fade'].aos-animate.main-content {
  transform: none;
}

div:has(> .piller-bg-1) {
  background-color: #ffffff;
}

div:has(> .piller-bg-2) {
  background-color: #def0fe;
}

div:has(> .piller-bg-3) {
  background-color: #bde1fc;
}

div:has(> .piller-bg-4) {
  background-color: #9cd2fa;
}

div:has(> .piller-bg-5) {
  background-color: #7bc3f8;
}

div:has(> .piller-bg-6) {
  background-color: #59b3f6;
}

div:has(> .piller-bg-7) {
  background-color: #43aaf8;
}

div:has(> .piller-bg-8) {
  background-color: #2da0fa;
}

div:has(> .piller-bg-9) {
  background-color: #1796fc;
}

div:has(> .piller-bg-10) {
  background-color: #008cff;
}

.padding-inherit {
  padding: inherit;
}

.hide-column-name {
  filter: blur(3px);
  -webkit-filter: blur(3px);
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
  pointer-events: none;
}

/* Start Review Page CSS */

.review-heading.page-top .right .form-group {
  margin-bottom: 0;
}

.review-heading.page-top .right .MuiButton-root {
  margin-left: 0;
}

.review-heading.page-top
  .form-group
  .MuiInputAdornment-root.MuiInputAdornment-positionStart {
  border-right: 0;
}

.review-heading.page-top
  .form-group
  .MuiTextField-root
  .MuiOutlinedInput-notchedOutline
  span {
  padding-left: 0;
  padding-right: 0;
}

.review-heading.page-top {
  padding-bottom: 34px;
}

.review-heading.page-top .right .MuiButton-root.MuiButton-outlined {
  font-size: 14px;
  line-height: 20px;
  padding: 11px 9px;
  height: 48px;
  min-width: 100px;
}

.review-via-block p {
  font-size: 14px;
  line-height: 18px;
  color: #212121;
  margin-bottom: 15px;
}

.review-via-block p.mb-0 {
  margin-bottom: 0;
}

.review-via-block p span {
  color: #737373;
}

.review-via-block p a {
  color: #eb4335;
}

.review-via-block h4 {
  margin-bottom: 15px;
  font-weight: 700;
}

.all-review-block h5 {
  font-weight: 700;
  color: #737373;
  margin-bottom: 15px;
}

.review-listing li {
  border: solid 1px#D8D8D8;
  border-radius: 6px;
  padding: 20px;
}

.review-listing li + li {
  margin-top: 20px;
}

.review-listing-heading {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.review-listing-heading-logo {
  background: rgba(0, 140, 255, 0.08);
  width: 40px;
  height: 40px;
  border-radius: 40px;
  padding: 10px;
}

.review-listing-heading-logo img {
  display: block;
  width: 100%;
  height: 100%;
}

.review-listing-heading .time {
  font-size: 14px;
  line-height: 21px;
  color: #737373;
}

.review-listing-heading-name {
  flex-grow: 1;
  padding-left: 16px;
  font-weight: 600;
}

.review-listing-heading-name p {
  font-weight: 700;
}

.review-listing-heading .star-rating {
  display: flex;
  align-items: center;
}

.review-listing-heading .star-rating img {
  width: 16px;
  height: 16px;
  margin: 0 3px 0 0;
}

.review-listing-heading .star-rating span {
  padding-left: 5px;
  font-size: 12px;
}

.review-listing-detail p {
  color: #737373;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}

.replay-btn-outer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 767px) {
  .review-heading.page-top .page-title,
  .review-heading.page-top .right .form-group {
    margin-bottom: 20px;
  }

  .review-listing-heading {
    display: block;
    position: relative;
    padding-left: 56px;
  }

  .review-listing-heading-logo {
    position: absolute;
    left: 0;
    top: 0;
  }

  .review-listing-heading-name {
    padding-left: 0;
  }
}

.go-to-tabs {
  font-size: 1rem;
  line-height: 1.5;
  color: #008cff !important;
  font-weight: 500;
}

.go-to-tabs img {
  height: 1.5rem;
  vertical-align: -0.4rem;
}

.center {
  color: #737373;
  text-align: center;
}

.text-justify-center {
  justify-content: center !important;
}

.right-button {
  margin-left: auto;
  padding-right: 30px;
}

.export-button {
  background: #fff;
  border: 1px solid #ddd !important;
}

.d-none {
  display: none;
}

li:has(.review-listing-detail > .bg-review) {
  background-color: #f1f6fb !important;
}

li:has(.review-listing-detail > .bg-review-remove) {
  background-color: #ffffff !important;
}

.inline-block {
  display: inline-block;
}

.review-summary {
  min-width: 150px;
  margin-top: 3px;
}

.card-add {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 30px);
  position: relative;
}

.card-add .MuiGrid-root {
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.d-flex {
  display: flex;
  flex-wrap: wrap;
}

.d-flex > div {
  flex: 50%;
  /* or - flex: 0 50% - or - flex-basis: 50% - */
  margin-bottom: 10px;
}

/* .datepicker-height-width input {
  
} */

.filter-error {
  color: #d32f2f;
  font-size: 12px;
  line-height: 14px;
  display: block;
  padding-top: 6px;
  margin-bottom: -20px;
}

.d-column {
  flex-direction: column;
  align-items: flex-end;
}

.last-active-seen {
  background-color: white;
  text-align: right;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  margin-top: 1px;
  font-size: 12px;
  color: #737373;
}

.last-seen-lable {
  font-weight: 400;
}

.last-seen-value {
  font-weight: 500;
}

.split-options .MuiButton-root:not(:last-child) {
  margin-bottom: 20px;
}

@media (min-width: 414px) {
  .split-options button {
    min-width: 240px;
  }
}

.split-options .MuiButton-root {
  border-radius: 6px;
  width: 100%;
  min-height: 56px;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0 20px;
  font-size: 20px;
  font-weight: 400;
  text-align: start;
  border-color: #cee9ff;
  text-transform: none;
}

.split-options .MuiButton-root img {
  display: block;
  height: 70px;
  width: 70px;
}

.split-options .MuiButton-root .icon-wrapper {
  display: flex;
  position: relative;
  z-index: 1;
  align-items: center;
  width: 140px;
  margin-left: auto;
  justify-content: flex-end;
  padding: 12px 20px;
}

.pointer {
  cursor: pointer;
  opacity: 1;
}

.disable-color {
  opacity: 0.38;
}

.review-cancel-button {
  margin-left: 5px !important;
  background-color: white !important;
  color: #008cff !important;
  border: 1px solid #008cff !important;
  box-shadow: none !important;
}

.error-css {
  color: #d32f2f;
  margin-left: 10px;
  font-size: 0.75rem;
  line-height: 1.66;
  font-weight: 400;
}

.promo-add-content {
  display: flex;
}

.promo-add-content > .left {
  flex-basis: calc(100% - 426px);
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 52px;
  max-width: calc(100% - 450px);
}

.promo-add-content > .right {
  flex-basis: 374px;
  flex-grow: 0;
  flex-shrink: 0;
  max-width: 400px;
  align-self: flex-start;
  position: sticky;
  top: 0;
}

.mb-0 {
  margin-bottom: 0px;
}

@media (max-width: 991px) {
  .promo-add-content {
    display: block;
  }

  .promo-add-content > .left {
    flex-basis: 100%;
    margin: 0 0 50px;
    max-width: 100%;
  }

  .promo-add-content > .right {
    margin: 0 -20px;
  }

  .margin-10 {
    margin: 5px;
  }

  .ml-10 {
    margin-left: 5px;
  }
}

.margin-10 {
  margin: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.font-18 {
  font-size: 18px;
  font-weight: 500;
}

.card > div:nth-child(2) {
  margin-top: 10px;
}

.text-blue {
  color: #222;
  font-weight: 600;
}

.promo-list-details ul {
  margin-left: 19px;
  padding: 0px;
}

.promo-list-details ul li {
  font-size: 14px;
  line-height: 18px;
  color: #666;
  font-weight: 400;
  padding: 8px 0px;
}

.tooltip-container {
  display: flex;
  align-items: center;
}

.tooltip-container .font-18 {
  margin-right: 4px;
  font-size: 18px;
  font-weight: 500;
}

.google-dropdown {
  margin-left: auto;
}
.google-dropdown .MuiSelect-outlined {
  padding: 0px;
  padding-right: 28px;
  font-size: 14px;
}
.google-dropdown .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  right: 0px;
}
.google-dropdown .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.google-flex {
  display: flex;
}

@media (max-width: 512px) {
  .google-flex {
    display: block;
  }
}

.google-review-button {
  padding: 8px 10px !important;
  margin-top: 30px !important;
}

.google-review-button img {
  width: 18px !important;
  height: 18px !important;
}

.right-align {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
}

.ml-5 {
  margin-left: 5px !important;
}

.apexcharts-menu-item {
  padding: 6px 7px;
  font-size: 12px;
  cursor: pointer;
  width: 110px;
}

.apexcharts-toolbar {
  top: 20px !important;
  right: 20px !important;
}
.apexcharts-canvas {
  position: inherit !important;
  user-select: none !important;
}

.dashboard-header-main {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.dashboard-header {
  display: flex;
  align-items: center;
}
.dashboard-header input {
  width: 140px;
}

@media screen and (max-width: 768px) {
  .dashboard-header {
    margin-bottom: 10px;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .internal-review-div {
    display: flex;
    margin-top: 15px;
    justify-content: flex-end;
  }
}

div.donut {
  display: flex;
  justify-content: center;
}

.internal-review-div {
  display: flex;
}

.server-review {
  min-width: 150px;
  margin-top: 3px;
}

.server-review:hover {
  background-color: #d8d8d8;
  border-radius: 4px;
  padding: 1px 1px;
}

.data-table-row-0 [data-id='0'] {
  background: #cee6f7;
  border-bottom: 2px solid black;
  pointer-events: none;
}

.data-table-row-0 [data-id='0'] .MuiDataGrid-cell {
  font-weight: 600;
}

.leaflet-control-attribution {
  display: none;
}

.custom-attribution {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 5px;
  background: white;
  z-index: 1000;
  font-size: 12px;
}

.scrollable-list {
  max-height: 170px;
  overflow-y: auto;
  padding-right: 5px;
}

/* custom grapes react
--------------------------------------------------------------------------------------------------------*/
.custom-grapes-react {
  border: 1px solid #e5e7eb;
  overflow: auto;
  height: 70vh;
  width: 100%;
}
.custom-grapes-react::-webkit-scrollbar {
  width: 1em;
}
.custom-grapes-react .gjs-one-bg {
  background-color: #fff !important;
}

.custom-grapes-react .gjs-two-color {
  color: #888 !important;
}

.custom-grapes-react .gjs-pn-active.gjs-pn-btn {
  background: #008cff;
  color: #fff;
  border-radius: 4px;
}

.custom-grapes-react .gjs-pn-buttons {
  justify-content: flex-start;
}

.custom-grapes-react .gjs-pn-buttons .gjs-pn-btn {
  margin-right: 8px;
}

.custom-grapes-react .gjs-pn-views {
  border-bottom: 1px solid #e5e7eb;
  left: 0;
  width: 35%;
  z-index: 4;
}

.custom-grapes-react .gjs-pn-views-container {
  height: 100%;
  padding: 40px 0 0;
  left: 0;
  width: 35%;
  overflow: auto;
  box-shadow: none;
}

.custom-grapes-react .gjs-pn-devices-c {
  display: inline-block;
  position: absolute;
  box-sizing: border-box;
  text-align: center;
  z-index: 3;
  right: 0;
}

.custom-grapes-react .gjs-pn-options {
  left: 35%;
  top: 0;
  right: auto;
}

.custom-grapes-react .gjs-frame {
  outline: medium none;
  height: 100%;
  width: 65%;
  border: none;
  margin: auto;
  display: block;
  transition: width 0.35s ease, height 0.35s ease;
  bottom: 0;
  left: 35%;
  right: 0;
}

.custom-grapes-react .custom-grapes-react {
  width: 100%;
}

.custom-grapes-react .gjs-pn-commands {
  width: 100%;
  box-shadow: none;
  border-bottom: 1px solid #e5e7eb;
}

.custom-grapes-react .gjs-cv-canvas {
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 40px);
  bottom: 0;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 40px;
  background: #fff;
}

.custom-grapes-react .gjs-block .gjs-block__media svg {
  fill: #9e9e9e;
  height: 30px;
}
.custom-grapes-react .gjs-block svg path {
  fill: #9e9e9e !important;
}
.custom-grapes-react .gjs-four-color-h:hover {
  color: #008cff;
}

.custom-grapes-react .gjs-block .gjs-block-label {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #707275;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.custom-grapes-react .gjs-block {
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
  width: calc(33.33% - 12px);
  min-width: 80px;
  padding: 12px;
  box-sizing: border-box;
  min-height: 100px;
  cursor: all-scroll;
  display: flex;
  flex-direction: column;
  border: 1px solid #e5e7eb;
  border-radius: 12px;
  margin: 0 6px 12px;
  transition: all 0.2s ease 0s;
  box-shadow: none;
  justify-content: center;
}

.custom-grapes-react div[title='Map'],
.custom-grapes-react div[title='Video'],
.custom-grapes-react div[title='Link Block'],
.custom-grapes-react .gjs-pn-btn[title='Preview'],
.custom-grapes-react .gjs-pn-btn[title='View code'] {
  display: none;
}

.custom-grapes-react .gjs-title {
  background-color: #fff !important;
  color: #000;
  font-size: 20px;
}

.create-campaign-dialog .campaign-content > .MuiButton-root.mt-10,
.create-campaign-dialog .campaign-location > .MuiButton-root.mt-10 {
  margin-top: 10px;
}

.custom-grapes-react .gjs-block__media {
  margin-bottom: 0px !important;
}

.custom-grapes-react .gjs-layer-name {
  padding: 5px 0;
  display: inline-block;
  box-sizing: content-box;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 30px 0 5px;
  max-width: 170px;
  height: 20px !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
}

@media (max-width: 768px) {
  .custom-grapes-react .gjs-frame .gjs-cell {
    width: auto;
    display: table-cell;
  }
}

.custom-grapes-react .gjs-block-category .gjs-title,
.custom-grapes-react .gjs-traits-label {
  border-bottom: none;
}

.custom-grapes-react .gjs-title,
.custom-grapes-react .gjs-trt-header,
.custom-grapes-react .gjs-sm-header .custom-grapes-react .gjs-pn-panel,
#grapesjs-react,
.custom-grapes-react .gjs-sm-header,
.custom-grapes-react .gjs-layer-title-inn,
.custom-grapes-react .gjs-traits-label,
.custom-grapes-react .gjs-clm-header-label,
.custom-grapes-react .gjs-clm-label-sel,
.custom-grapes-react .gjs-sm-icon .custom-grapes-react .gjs-sm-sector-label {
  font-family: 'Poppins', sans-serif;
  font-weight: 500 !important;
}

.custom-grapes-react .gjs-block-category,
.custom-grapes-react .gjs-block-category.gjs-open {
  border-bottom: 1px solid #e6e8ef;
}

.custom-grapes-react .gjs-block-category .gjs-title,
.custom-grapes-react .gjs-sm-sector .gjs-sm-sector-title {
  min-height: 48px;
  padding: 12px 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.custom-grapes-react .gjs-sm-sector.gjs-sm-open {
  border-bottom: 1px solid #e6e8ef;
}

.custom-grapes-react .gjs-sm-sector .gjs-sm-sector-title {
  background: #fff;
  border-bottom: 1px solid #e6e8ef;
}

.custom-grapes-react .gjs-sm-sector .gjs-sm-properties {
  padding: 0 10px 4px 10px;
}

.custom-grapes-react .gjs-sm-sector.gjs-sm-open .gjs-sm-sector-title {
  border-bottom: 0;
}

.custom-grapes-react .gjs-sm-field.gjs-sm-composite {
  background: #f7f7f7;
  border-radius: 6px;
  border: none;
}

.custom-grapes-react .gjs-sm-composite .gjs-sm-properties {
  padding: 8px;
  padding-bottom: 4px;
}

.custom-grapes-react .gjs-sm-sector .gjs-sm-property {
  padding: 0 6px;
  margin-bottom: 12px;
}

.custom-grapes-react .gjs-sm-sector .gjs-sm-sector-title .gjs-sm-sector-label {
  margin-left: 0;
}

.custom-grapes-react .gjs-block-category .gjs-blocks-c {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 6px;
}

.custom-grapes-react .gjs-sm-property .gjs-sm-label {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #707275;
  margin: 0 0 6px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.custom-grapes-react .gjs-sm-property .gjs-sm-label .gjs-sm-clear {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-grapes-react .gjs-sm-property .gjs-fields input,
.custom-grapes-react .gjs-sm-property .gjs-fields select,
.custom-grapes-react .gjs-clm-tags #gjs-clm-new {
  padding: 10px 12px;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
}

.custom-grapes-react .gjs-sm-property .gjs-fields select,
.custom-grapes-react #gjs-clm-states {
  background-image: url('./assets/images/ic-down-fill-small.svg');
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: center right 8px;
}

.custom-grapes-react .gjs-sm-property .gjs-fields .gjs-field-units select {
  border: none;
  padding-left: 0;
  padding-right: 0;
  height: 46px;
  width: 40px;
  margin-top: 1px;
}

.custom-grapes-react .gjs-sm-property .gjs-fields .gjs-field .gjs-field-arrows {
  display: none;
}

.custom-grapes-react .gjs-sm-property .gjs-fields .gjs-field .gjs-field-units {
  right: 1px;
}

.custom-grapes-react
  .gjs-sm-property
  .gjs-fields
  .gjs-field
  .gjs-field-units
  select {
  background-image: none;
}

.custom-grapes-react .gjs-sm-property .gjs-fields .gjs-field {
  background: transparent;
}

.custom-grapes-react .gjs-sm-property .gjs-fields .gjs-field .gjs-radio-items {
  border-radius: 6px;
  padding: 4px;
  background: #f3f4f6;
}

.custom-grapes-react .gjs-sm-property .gjs-fields .gjs-field .gjs-radio-item {
  padding: 0;
  border: none;
  background: transparent;
}

.custom-grapes-react .gjs-sm-property .gjs-fields .gjs-field .gjs-field-colorp {
  width: 40px;
  padding: 4px 5px 4px 4px;
}

.custom-grapes-react
  .gjs-sm-property
  .gjs-fields
  .gjs-field
  .gjs-field-colorp
  .gjs-field-color-picker {
  border-radius: 2px 4px 4px 2px;
}

.custom-grapes-react
  .gjs-sm-property
  .gjs-fields
  .gjs-field
  .gjs-field-colorp
  .gjs-checker-bg {
  background-image: none;
}

.custom-grapes-react
  .gjs-sm-property
  .gjs-fields
  .gjs-field
  .gjs-radio-item
  .gjs-radio-item-label {
  font-family: 'Poppins';
  padding: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #111827;
  padding: 4px;
  height: 28px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-grapes-react
  .gjs-sm-property
  .gjs-fields
  .gjs-field
  .gjs-radio-item
  input:checked
  + .gjs-radio-item-label {
  background: #fff;
}

.custom-grapes-react .gjs-clm-header {
  padding: 0 6px;
}

.custom-grapes-react .gjs-clm-header .gjs-clm-header-label {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  color: #212121;
}

.custom-grapes-react .gjs-clm-sels-info {
  margin: 0;
  padding: 0 6px;
}

.custom-grapes-react .gjs-clm-sels-info .gjs-clm-label-sel {
  color: #666666;
}

.custom-grapes-react .gjs-clm-sels-info .gjs-clm-sel {
  color: #212121;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.custom-grapes-react .gjs-clm-tags {
  padding: 10px;
  padding-bottom: 0;
}

.custom-grapes-react .gjs-clm-tag {
  background: #008cff;
  margin-bottom: 6px;
  margin-right: 6px;
}

.custom-grapes-react .gjs-clm-tags #gjs-clm-tags-field {
  padding: 6px;
  margin-bottom: 0;
}

.custom-grapes-react .gjs-clm-tag #gjs-clm-tag-label {
  color: #fff;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  padding: 0 4px;
}

.custom-grapes-react .gjs-clm-tags #gjs-clm-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-grapes-react .gjs-clm-tags #gjs-clm-checkbox svg {
  fill: #fff;
}

.custom-grapes-react .gjs-clm-tags .gjs-clm-tag-status,
.custom-grapes-react .gjs-clm-tags .gjs-clm-tag-close {
  height: 16px;
  width: 16px;
}

.custom-grapes-react .gjs-clm-tags #gjs-clm-add-tag,
.custom-grapes-react .gjs-clm-tags .gjs-clm-tags-btn {
  height: 28px;
  width: 28px;
}

.custom-grapes-react #gjs-clm-states {
  background-position: center right;
  padding-right: 20px;
  color: #212121;
  font-family: 'poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.custom-grapes-react .gjs-field {
  background: transparent;
}

.custom-grapes-react .gjs-sm-stack #gjs-sm-add {
  opacity: 1;
  fill: #707275;
  color: #707275;
  width: 16px;
  height: 16px;
  right: 1px;
  top: -27px;
}

.custom-grapes-react .gjs-sm-stack #gjs-sm-add svg {
  fill: #707275;
}

.custom-grapes-react
  .gjs-sm-property
  .gjs-sm-label.gjs-four-color
  + .gjs-fields
  #gjs-sm-add {
  right: 18px;
}

.custom-grapes-react .gjs-sm-layers {
  padding: 0;
  margin: 0;
  min-height: auto;
}

.custom-grapes-react .gjs-sm-layer:not(:last-child) {
  margin: 0 0 8px 0;
}

.custom-grapes-react .gjs-sm-layers .gjs-sm-layer {
  border: 1px solid #d1d5db;
  border-radius: 6px;
  padding: 8px;
}

.custom-grapes-react .gjs-sm-layers .gjs-sm-layer.gjs-sm-active {
  padding-bottom: 0;
}

.custom-grapes-react .gjs-sm-layer #gjs-sm-move {
  margin-bottom: 2px;
}

.custom-grapes-react .gjs-sm-layer #gjs-sm-label {
  margin: 2px 6px 0;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #111827;
}

.custom-grapes-react .gjs-sm-layer .gjs-sm-layer-preview-cnt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-grapes-react .gjs-sm-layer #gjs-sm-inputs {
  margin: 0 -4px;
}

.custom-grapes-react .gjs-sm-layer .gjs-sm-properties {
  padding: 8px 0 0;
}

.custom-grapes-react .gjs-sm-property .gjs-sm-btn {
  background: #008cff;
  box-shadow: none;
  border: none;
  color: #fff;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.custom-grapes-react .gjs-sm-sector .gjs-sm-property.gjs-sm-slider .gjs-fields {
  gap: 12px;
}

.custom-grapes-react .gjs-layer .gjs-layer-title {
  padding-top: 7px;
  padding-bottom: 4px;
  border-bottom: 1px solid #d1d5db;
  background-color: #fff;
  padding-left: 20px;
}

.custom-grapes-react .gjs-layer .gjs-layer-children .gjs-layer-title {
  padding-left: 30px;
}

.custom-grapes-react .gjs-layer .gjs-layer-no-chld {
  padding-left: 40px;
}

.custom-grapes-react .gjs-layer .gjs-layer-title .gjs-layer-name,
.custom-grapes-react .gjs-layer .gjs-layer-count {
  color: #212121;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}

.custom-grapes-react .gjs-layer .gjs-layer-title .gjs-layer-name {
  margin-left: 12px;
}

.custom-grapes-react .gjs-layer .gjs-layer-count {
  color: #666;
  padding-top: 3px;
}

.custom-grapes-react .gjs-layer .gjs-layer-title .gjs-layer-caret {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2px;
}

.custom-grapes-react
  .gjs-layer.gjs-selected
  > .gjs-layer-title-c
  .gjs-layer-title {
  background: #008cff;
  color: #fff;
}

.custom-grapes-react
  .gjs-layer.gjs-selected
  > .gjs-layer-title-c
  .gjs-layer-title
  .gjs-layer-name {
  color: #fff;
}

.custom-grapes-react .gjs-layer.gjs-selected > .gjs-layer-count {
  color: #fff;
}

.custom-grapes-react
  .gjs-layer:not(.gjs-selected).gjs-hovered
  > .gjs-layer-title-c
  .gjs-layer-title {
  background: #f4f4f4;
}

.load-html-preview {
  background: #007ee6;
  font-size: 16px;
  color: #fff;
  box-shadow: none;
  border: 1px solid #007ee6;
  padding: 12px 22px;
  font-family: 'Poppins', sans-serif;
  border-radius: 6px;
  padding-top: 12px;
  padding-bottom: 12px;
  line-height: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  margin-top: 5px;
  justify-content: center;
  cursor: pointer;
}

.mt-15 {
  margin-top: 15px;
}
.choose-template .template-details {
  border: 1px solid #008cff;
  width: 200px;
  height: 250px;
}

.choose-template .template-details img {
  width: 160px;
  height: 210px;
  margin-left: 15px;
  margin-top: 10px;
}

.choose-template .template-details p {
  text-align: center;
}
.template-details:hover .choose-btn {
  left: 60px;
  bottom: 140px;
  display: block;
}

.template-details .choose-btn {
  display: none;
}

.right-icon .MuiButton-root.MuiButtonBase-root {
  min-width: 0px;
  padding: 0px;
}

/* menu manager
--------------------------------------------------------------------------------------------------------*/
.menu-manager-no-data {
  font-size: 18px;
  color: rgba(0, 0, 0, 0.5);
  text-align: center;
}

.menu-manager-body {
  margin-top: 5px;
}

.modifiers-grid {
  border-top: 1px solid rgba(224, 224, 224, 1) !important;
}

.menu-manager-accordain .accrodian-body {
  margin-bottom: 25px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: none !important;
}

.menu-manager-accordain .accrodian-summary {
  border: 1px solid #ddd;
  padding-bottom: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.menu-manager-accordain .accrodian-grid {
  padding: 15px 0 0 0;
}

.menu-manager-accordain .accrodian-grid {
  display: flex;
  flex-direction: column;
}

.accrodian-user-icon {
  height: 42px;
  width: 42px;
  margin-right: 6px;
  background: rgba(216, 216, 216, 0.4);
  width: 42px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MuiModal-root.edit-menu-item-dialog .MuiDialog-paper {
  max-width: 1100px;
}

.MuiModal-root.edit-menu-item-dialog .MuiDialog-paper .MuiDialogContent-root {
  padding: 0 50px 40px !important;
  position: relative;
  margin-top: -30px;
  z-index: 1;
}

.MuiModal-root.edit-menu-item-dialog
  .MuiDialog-paper
  .MuiDialogContent-root
  .stepper-wrapper
  .MuiStepper-root {
  max-width: 800px;
  margin: 0 auto 40px;
}

.MuiModal-root.edit-menu-item-dialog .MuiDialogTitle-root {
  padding: 10px;
}

.edit-menu-item-dialog-availablity-container {
  display: flex;
  align-items: center;
  margin-top: 35px;
}

.edit-menu-item-dialog-availablity {
  font-size: 24px;
  font-weight: 600;
  margin-left: 10px;
}

.edit-menu-item-dialog-note {
  font-size: 16px;
  margin: 20px 0px 20px 36px;
  color: #737373;
}

.edit-menu-item-dialog-mark-btn {
  border: 2px solid black !important;
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 18px !important;
}

.edit-menu-item-dialog-grid {
  margin-top: 50px !important;
}

.edit-menu-item-dialog-file-container {
  min-height: 350px;
  /* min-width: 390px; */
  border: 1px solid #ddd;
  border-radius: 5%;
}

.edit-menu-item-dialog-btns-container {
  margin-top: 60px !important;
}

@media (max-width: 550px) {
  .edit-menu-item-dialog-file-container {
    min-width: 100%;
  }
}
